import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import logo from 'assets/images/brand-logo.svg';

import Modal from 'components/common/modal/Modal';
import OTPInput from 'components/common/OTPInput/OTPInput';

import { HOME } from 'constants/routes';
import { MFA_ACTIONS, MFA_OTP_LENGTH } from 'constants/auth';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

import { rememberDevice } from 'services/mfa';

const MFA = ({ mfaData, handleLoginWithMFA }) => {
  const [OTP, setOTP] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
  const [isRemembering, setIsRemembering] = useState(false);

  const navigate = useNavigate();

  const { action, qr } = mfaData;

  const onVerify = async (e) => {
    e.preventDefault();
    try {
      setIsVerifyingOTP(true);
      const data = await handleLoginWithMFA(e, OTP);

      if (data && data.access_token) {
        // clear any previous error after successful verification
        setError('');

        setShowModal(true);
      }
    } catch (error) {
      let errorMessage = DEFAULT_ERROR_MESSAGE;

      if (error.response && error.response.data) {
        errorMessage = error.response.data.detail;
      }
      setError(errorMessage);
    } finally {
      setIsVerifyingOTP(false);
    }
  };

  const handleRemember = async () => {
    try {
      setIsRemembering(true);

      const data = await rememberDevice();

      if (data.success) {
        // clear any previous error after successful verification, then navigate to HOME
        setError('');
        navigate(HOME);
      }
    } catch (err) {
      let errorMessage = DEFAULT_ERROR_MESSAGE;

      if (error.response && error.response.data) {
        errorMessage = error.response.data.detail;
      }
      setError(errorMessage);
    } finally {
      setIsRemembering(false);
      handleModalClose();
    }
  };

  const handleRememberNotNeeded = () => {
    handleModalClose();
    navigate(HOME);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  // adding an event listener for Enter key
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.key === 'Enter' &&
        OTP.length === MFA_OTP_LENGTH &&
        !isVerifyingOTP
      ) {
        onVerify(event);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // on unmount, remove event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [OTP]);

  return (
    <main className="mfa__page d-flex justify-content-evenly">
      <div className="mfa__left bg-primary--base d-flex align-items-center justify-content-center">
        <img src={logo} alt="MFA Page" />
      </div>
      <div className="mfa__right gap-8x d-flex align-items-center justify-content-center">
        <div className="mfa__right-container p-4x">
          {action && action === MFA_ACTIONS.SETUP_MFA && (
            <div className="mfa__instructions gap-2x">
              <h4>Setup the authenticator app in 3 steps</h4>
              <ol className="gap-1x pl-4x">
                <li className="text-lg">
                  Install or open a third party authenticator app like Google
                  Authenticator, Authy, etc.
                </li>
                <li className="text-lg">Scan the QR with the authenticator </li>
                <li className="text-lg">
                  Enter the 6-digit code you see in the authenticator
                </li>
              </ol>
              {qr && (
                <div className="mfa__qr">
                  <img src={`data:image/png;base64, ${qr}`} />
                </div>
              )}
            </div>
          )}

          <div className="d-flex flex-direction-column">
            <div className="mb-6x mt-6x">
              <h4>Enter the {MFA_OTP_LENGTH}-digit code</h4>
              <p>
                You can find the {MFA_OTP_LENGTH}-digit code in the
                authenticator app on your phone
              </p>
            </div>
            <OTPInput
              onChange={(otp) => setOTP(otp)}
              error={error}
              length={MFA_OTP_LENGTH}
              shouldAutoFocus
            />
            <button
              className={classNames('btn btn-primary w-100 mt-6x', {
                'has-loader': isVerifyingOTP,
              })}
              onClick={onVerify}
              disabled={OTP.length < MFA_OTP_LENGTH || isVerifyingOTP}
            >
              <b>Proceed to Verification</b>
              {isVerifyingOTP && <span className="spinner" />}
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal>
          <div className="mb-3x">
            <h3>Remember this device?</h3>
            <p className="color-grey--80">
              If you choose to remember this device, you won't need to use
              2-factor authentication for future logins from here.
            </p>
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn" onClick={handleRememberNotNeeded}>
              Not Needed
            </button>
            <button
              className={classNames('btn btn-primary', {
                'has-loader': isRemembering,
              })}
              onClick={handleRemember}
              disabled={isRemembering}
            >
              Remember
              {isRemembering && <span className="spinner" />}
            </button>
          </div>
        </Modal>
      )}
    </main>
  );
};

MFA.defaultProps = {
  mfaData: {},
};

MFA.propTypes = {
  mfaData: PropTypes.object,
  handleLoginWithMFA: PropTypes.func,
};

export default MFA;
