import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Sidebar from 'components/sidebar/Sidebar';
import Toast from 'components/common/toast/Toast';
import Table from 'components/common/table/Table';
import Modal from 'components/common/modal/Modal';
import Loader from 'components/common/loader/Loader';
import Dropdown from 'components/common/dropdown/Dropdown';
import ContinueButton from 'components/common/continueButton/continueButton';

import ConfirmationModal from './ConfirmationModal';

import { tableConfig } from './tableConfig';
import { columnConfig, RULE_BASE_ID, PROJECT_ID } from './columnConfig';

import * as routes from 'constants/routes';
import { QA } from 'constants/projectStatuses';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

import {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
} from 'actions/clientAction';

import { updateProject } from 'services/projectAnalysis';
import * as ruleLibraryServices from 'services/ruleLibrary';

import { formatDateWithTime } from 'utils/common/formatter';
import EmptyProjectPage from 'components/common/emptypage/EmptyProjectPage';

const mapStateToProps = (state) => {
  const { selectedClientId, clientList, selectedProject } = state;

  return { selectedClientId, clientList, selectedProject };
};

class RuleLibrary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      navigateTo: '',
      hasError: false,
      errorMessage: '',
      isLoading: false,
      selectedData: [],
      hasNewData: false,
      hasChanges: false,
      isConnecting: false,
      isShowingModal: false,
    };
  }

  async componentDidMount() {
    await this.getRulesData();
  }

  getRulesData = async () => {
    const { selectedClientId, selectedProject } = this.props;

    if (selectedProject.projectId) {
      this.setState({
        isLoading: true,
      });

      try {
        const data = await ruleLibraryServices.getAssociatedRulesForProject(
          selectedProject.projectId,
          selectedClientId
        );
        const rowsToBeSelected = data
          .filter(
            (singleData) =>
              singleData[PROJECT_ID] === parseInt(selectedProject.projectId)
          )
          .map((singleData) => singleData[RULE_BASE_ID]);

        this.setState({
          data,
          selectedData: rowsToBeSelected,
          isLoading: false,
        });
      } catch (error) {
        const errorMessage =
          error.response.data.detail || DEFAULT_ERROR_MESSAGE;

        this.setState({
          errorMessage,
          hasError: true,
          isLoading: false,
        });
      }
    }
  };

  resetErrorMessage = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.setState({
      navigateTo: routes.PROJECT_ANALYSIS,
    });

    this.props.updateClientID(clientId);
    this.props.clearSelectedProject();
  };

  handleRowSelection = (selectedData) => {
    this.resetErrorMessage();
    this.setState({
      hasChanges: true,
      hasNewData: false,
      selectedData: selectedData.map((row) => row[RULE_BASE_ID]),
    });
  };

  handleConnectToProject = () => {
    if (this.state.selectedData.length === 0) {
      this.setState({
        hasError: true,
        errorMessage: 'Please select a rule.',
      });

      return;
    }

    this.setState({
      isShowingModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isShowingModal: false,
    });
  };

  handleConfirmationAction = async () => {
    const { selectedProject } = this.props;
    const { selectedData } = this.state;

    if (selectedProject.projectId) {
      this.setState({
        isConnecting: true,
      });

      try {
        await ruleLibraryServices.connectRulesToProject(
          selectedProject.projectId,
          selectedData
        );

        this.setState({
          hasChanges: false,
          isConnecting: false,
          isShowingModal: false,
        });
      } catch (error) {
        this.setState({
          isConnecting: false,
          isShowingModal: false,
        });
      }
    }
  };

  handleCancelAction = async () => {
    this.setState({
      isShowingModal: false,
    });

    await this.getRulesData();

    this.setState({
      hasNewData: true,
    });
  };

  handleNextStep = async () => {
    const { selectedClientId, selectedProject, updateSelectedProject } =
      this.props;

    if (this.state.selectedData.length === 0) {
      this.setState({
        hasError: true,
        errorMessage: 'Please select a rule.',
      });

      return;
    }

    if (this.state.hasChanges) {
      this.setState({
        isShowingModal: true,
      });

      return;
    }

    if (selectedProject.projectId) {
      this.setState({
        isLoading: true,
      });

      try {
        const data = await updateProject(
          selectedClientId,
          selectedProject.projectId,
          {
            status: QA.toUpperCase(),
          }
        );

        updateSelectedProject({
          ...selectedProject,
          status: QA.toUpperCase(),
          lastUpdateDate: formatDateWithTime(data.update_date),
        });

        this.setState({
          isLoading: false,
          navigateTo: routes.QA,
        });
      } catch (error) {
        const errorMessage =
          error.response.data.detail || DEFAULT_ERROR_MESSAGE;

        this.setState({
          errorMessage,
          hasError: true,
          isLoading: false,
        });
      }
    }
  };

  render() {
    const {
      data,
      hasError,
      isLoading,
      hasNewData,
      navigateTo,
      errorMessage,
      selectedData,
      isConnecting,
      isShowingModal,
    } = this.state;
    const { clientList, selectedClientId, selectedProject } = this.props;

    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );
    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });
    const projectDisplayName = selectedProject.projectName || '';
    const componentClassName = classNames({
      'd-flex': true,
      'empty-page': !selectedClientId,
    });

    return navigateTo ? (
      <Navigate to={navigateTo} replace={true} />
    ) : (
      <main>
        <div className={componentClassName}>
          <Sidebar />
          <div className="main-content px-5x pb-5x d-flex flex-direction-column">
            <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
              <div className="profile mb-3x ml-auto">
                <Dropdown
                  label={displayName}
                  dropdownItems={dropdownItems}
                  onClick={this.handleSelectClient}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center mb-1x">
                <h1>
                  Rule Library{' '}
                  {projectDisplayName && <span>({projectDisplayName})</span>}
                </h1>
                {selectedProject.status && (
                  <div className="badge badge-lg mr-auto ml-5x mt-3x">
                    {selectedProject.status}
                  </div>
                )}

                <div className="color-grey--60 mt-4x">
                  Last Updated : {selectedProject.lastUpdateDate}
                </div>
              </div>
            </div>

            {isLoading && <Loader isFullScreen={true} />}
            {hasError && (
              <Toast
                title={errorMessage}
                hasError={hasError}
                handleClose={this.resetErrorMessage}
              />
            )}
            {isShowingModal && (
              <Modal onClose={this.handleCloseModal}>
                <ConfirmationModal
                  heading={`Connect Rules to project ${selectedProject.projectName}?`}
                  onCancel={this.handleCancelAction}
                  onDelete={this.handleConfirmationAction}
                  isRunningAction={isConnecting}
                />
              </Modal>
            )}
            {selectedProject.projectId ? (
              <div>
                <div
                  className="d-flex flex-direction-column gap-3x"
                  style={{ height: 'calc(100vh - 132px)' }}
                >
                  <div className="table has-box-shadow">
                    <Table
                      className="has-box-shadow"
                      tableConfig={tableConfig}
                      columnConfig={columnConfig}
                      onRowSelection={this.handleRowSelection}
                      pagination={false}
                      data={data}
                      selectedRows={selectedData}
                      hasNewData={hasNewData}
                    />
                  </div>

                  <div className="d-flex flex-direction-column align-items-end">
                    <button
                      className="btn btn-primary has-loader"
                      onClick={this.handleConnectToProject}
                    >
                      Connect to the Project
                    </button>
                  </div>

                  <div className="mt-auto">
                    <ContinueButton onClick={this.handleNextStep} />
                  </div>
                </div>
              </div>
            ) : (
              <EmptyProjectPage />
            )}
          </div>
        </div>
      </main>
    );
  }
}

RuleLibrary.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  selectedProject: PropTypes.object,
  clearSelectedProject: PropTypes.func,
  updateSelectedProject: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
})(RuleLibrary);
