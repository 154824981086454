export const removeEmptyPayloadValues = (
  payloadObj,
  removeEmptyString = false
) => {
  let cleanedPayload = {};

  const payloadKeys = Object.keys(payloadObj);
  const filteredKeys = payloadKeys.filter((key) => {
    if (!removeEmptyString) {
      return payloadObj[key] !== undefined && payloadObj[key] !== null;
    }

    return (
      payloadObj[key] !== undefined &&
      payloadObj[key] !== null &&
      payloadObj[key] !== ''
    );
  });

  filteredKeys.forEach((key) => {
    cleanedPayload = {
      ...cleanedPayload,
      [key]: payloadObj[key],
    };
  });

  return cleanedPayload;
};

export const findMissingInput = (payloadObj, requiredInputs) => {
  const payloadKeys = Object.keys(payloadObj);
  const requiredFields = Object.keys(requiredInputs);

  let missingInput = '';

  requiredFields.every((field) => {
    let foundKey = payloadKeys.find((key) => field === key);

    if (!foundKey) {
      missingInput = requiredInputs[field];

      return false;
    }

    if (!payloadObj[foundKey] && payloadObj[foundKey] !== false) {
      missingInput = requiredInputs[field];

      return false;
    }

    return true;
  });

  return missingInput;
};

/**
 * A util to convert the form data to payload suitable for API
 * @param {*} data - data from the form
 * @returns {*} object that is suitable for API consumption
 */
export const convertToAddUserPayload = (data) => {
  const payloadWithoutEmptyValues = removeEmptyPayloadValues(data, true);

  // delete confirmPassword
  delete payloadWithoutEmptyValues.confirmPassword;

  // remove empty arrays
  for (let key in payloadWithoutEmptyValues) {
    if (
      Array.isArray(payloadWithoutEmptyValues[key]) &&
      payloadWithoutEmptyValues[key].length === 0
    ) {
      delete payloadWithoutEmptyValues[key];
    }
  }

  return payloadWithoutEmptyValues;
};
