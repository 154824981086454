import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Sidebar from 'components/sidebar/Sidebar';
import Toast from 'components/common/toast/Toast';
import Loader from 'components/common/loader/Loader';
import SelectBox from 'components/common/select/Select';
import Dropdown from 'components/common/dropdown/Dropdown';
import Datepicker from 'components/common/datepicker/Datepicker';
import EmptyProjectPage from 'components/common/emptypage/EmptyProjectPage';

import * as routes from 'constants/routes';
import { statementFormInputs } from 'constants/requiredFormInputs';
import { DEFAULT_ERROR_MESSAGE, MISSING_INPUT } from 'constants/errorMessages';

import { findMissingInput } from 'utils/payload';
import { interpolate } from 'utils/common/string';
import { formatNumberToLocaleString } from 'utils/common/formatter';

import {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
} from 'actions/clientAction';

import {
  getStatementData,
  updateStatementData,
  getStatementCategories,
} from 'services/statement';

const mapStateToProps = (state) => {
  const { selectedClientId, clientList, selectedProject } = state;

  return { selectedClientId, clientList, selectedProject };
};

class Statement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        statementName: '',
        totalBalance: '',
        initialInvoicedAmount: '',
        statementTimeperiod: '',
        status: '',
        clientReceiptDate: '',
        invoiceNumber: '',
        statementNotes: '',
      },
      draftData: {
        statementName: '',
        totalBalance: '',
        initialInvoicedAmount: '',
        statementTimeperiod: '',
        status: '',
        clientReceiptDate: '',
        invoiceNumber: '',
        statementNotes: '',
      },
      isSaving: '',
      navigateTo: '',
      hasError: false,
      errorMessage: '',
      isLoading: false,
      hasChange: false,
      selectedStatus: {},
      statusCategories: [],
    };
  }

  async componentDidMount() {
    const { selectedClientId, selectedProject } = this.props;

    if (!selectedClientId || !selectedProject.projectId) {
      return;
    }

    await this.getStatementCategories();
    await this.getStatementData();
  }

  getStatementCategories = async () => {
    this.setState({
      isLoading: true,
    });

    try {
      const categoriesResponse = await getStatementCategories();
      const statusCategories = categoriesResponse
        .sort((a, b) => a.priority > b.priority)
        .map((category) => {
          return {
            label: category.dictionary_name,
            value: category.dictionary_value,
          };
        });

      this.setState({
        statusCategories,
        isLoading: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  getStatementData = async () => {
    const { selectedProject } = this.props;

    if (!selectedProject.projectId) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    try {
      const data = await getStatementData(selectedProject.projectId);
      const cleanedData = this.cleanStatementResponse(data);
      this.setState({
        data: cleanedData,
        draftData: cleanedData,
        isLoading: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  cleanStatementResponse = (response) => {
    if (Object.keys(response).length === 0) {
      return {
        statementName: '',
        totalBalance: '',
        initialInvoicedAmount: '',
        statementTimeperiod: '',
        status: '',
        clientReceiptDate: '',
        invoiceNumber: '',
        statementNotes: '',
      };
    }

    return {
      statementName: response.statement_name || '',
      totalBalance: formatNumberToLocaleString(response.total_balance) || '',
      initialInvoicedAmount:
        formatNumberToLocaleString(response.initial_invoiced_amount) || '',
      statementTimeperiod: response.statement_timeperiod || '',
      status: response.status || '',
      clientReceiptDate: response.client_receipt_date || '',
      invoiceNumber: response.invoice_number || '',
      statementNotes: response.statement_notes || '',
    };
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.setState({
      navigateTo: routes.PROJECT_ANALYSIS,
    });

    this.props.updateClientID(clientId);
    this.props.clearSelectedProject();
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      draftData: {
        ...this.state.draftData,
        [name]: value,
      },
      hasChange: true,
    });
  };

  handleDateChange = (selectedDate) => {
    this.setState({
      draftData: {
        ...this.state.draftData,
        clientReceiptDate: selectedDate,
      },
      hasChange: true,
    });
  };

  handleSelectStatus = (selectedOption) => {
    this.setState({
      draftData: {
        ...this.state.draftData,
        status: selectedOption.value,
      },
      hasChange: true,
    });
  };

  handleSave = async () => {
    const { selectedProject, selectedClientId } = this.props;
    const { draftData } = this.state;

    if (!selectedProject.projectId) {
      return;
    }

    const statementPayload = {
      ...draftData,
      clientId: parseInt(selectedClientId),
    };

    const missingInput = findMissingInput(draftData, statementFormInputs);
    if (missingInput) {
      this.setState({
        hasError: true,
        errorMessage: interpolate(MISSING_INPUT, { name: missingInput }),
      });

      return;
    }

    this.setState({
      isSaving: true,
      errorMessage: '',
      hasError: false,
    });

    try {
      await updateStatementData(selectedProject.projectId, statementPayload);

      this.setState({
        hasChange: false,
        isSaving: false,
        data: draftData,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        hasError: true,
        errorMessage,
        hasChange: false,
        isSaving: false,
        data: draftData,
      });
    }
  };

  handleCancel = () => {
    const { data } = this.state;

    this.setState({
      draftData: data,
      hasChange: false,
    });
  };

  resetError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      isSaving,
      hasError,
      draftData,
      isLoading,
      hasChange,
      navigateTo,
      errorMessage,
      statusCategories,
    } = this.state;
    const {
      statementName,
      totalBalance,
      initialInvoicedAmount,
      statementTimeperiod,
      status,
      clientReceiptDate,
      invoiceNumber,
      statementNotes,
    } = draftData;

    const { clientList, selectedClientId, selectedProject } = this.props;

    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );
    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });
    const selectedStatus =
      statusCategories.find(
        (category) => category.value.toUpperCase() === status.toUpperCase()
      ) || {};
    const projectDisplayName = selectedProject.projectName || '';
    const inputClassname = classNames({
      form__control: true,
    });
    const componentClassName = classNames({
      'd-flex': true,
      'empty-page': !selectedClientId || !selectedProject.projectId,
    });

    return navigateTo ? (
      <Navigate to={navigateTo} replace={true} />
    ) : (
      <>
        <main>
          <div className={componentClassName}>
            <Sidebar />
            <div className="main-content px-5x pb-5x d-flex flex-direction-column">
              <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
                <div className="profile mb-3x ml-auto">
                  <Dropdown
                    label={displayName}
                    dropdownItems={dropdownItems}
                    onClick={this.handleSelectClient}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-1x">
                  <h1>
                    Statement{' '}
                    {projectDisplayName && <span>({projectDisplayName})</span>}
                  </h1>
                  {selectedProject.status && (
                    <div className="badge badge-lg mr-auto ml-5x mt-3x">
                      {selectedProject.status || ''}
                    </div>
                  )}

                  <div className="color-grey--60 mt-4x">
                    Last Updated : {selectedProject.lastUpdateDate}
                  </div>
                </div>
              </div>

              {isLoading && <Loader isFullScreen={true} />}
              {selectedClientId && selectedProject.projectId ? (
                <form
                  action=""
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  <div className="bg-white--base p-5x border-radius-4">
                    <div className="d-flex justify-content-between gap-12x ">
                      <div className="w-50 ">
                        <div className="form-group  mb-6x">
                          <label className="form__label">Statement Name</label>
                          <input
                            className={inputClassname}
                            type="text"
                            name="statementName"
                            value={statementName}
                            onChange={this.handleInputChange}
                            placeholder="Enter Statement Name"
                          />
                        </div>
                        <div className="form-group mb-6x">
                          <div className="row">
                            <div className="col-6">
                              <label className="form__label">
                                Total Balance
                              </label>
                              <input
                                className={inputClassname}
                                type="text"
                                name="totalBalance"
                                value={totalBalance}
                                onChange={this.handleInputChange}
                                placeholder="Enter Total Balance"
                              />
                            </div>
                            <div className="col-6">
                              <label className="form__label">
                                Total Invoice Amount
                              </label>
                              <input
                                className={inputClassname}
                                type="text"
                                name="initialInvoicedAmount"
                                value={initialInvoicedAmount}
                                onChange={this.handleInputChange}
                                placeholder="Enter Total Invoice Amount"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-6x">
                          <div className="row">
                            <div className="col-6">
                              <label className="form__label">Time Period</label>
                              <input
                                className={inputClassname}
                                type="text"
                                name="statementTimeperiod"
                                value={statementTimeperiod}
                                onChange={this.handleInputChange}
                                placeholder="Enter Time Period"
                              />
                            </div>
                            <div className="col-6">
                              <label className="form__label">Status</label>
                              <SelectBox
                                options={statusCategories}
                                value={selectedStatus}
                                onChange={this.handleSelectStatus}
                                placeholder="Select Status"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-6x">
                          <div className="row">
                            <div className="col-6">
                              <label className="form__label">
                                Client Receipt Date
                              </label>
                              <Datepicker
                                selectedDate={clientReceiptDate}
                                handleCalendarValueChange={
                                  this.handleDateChange
                                }
                                placeholder={'Enter Client Receipt Date'}
                              />
                            </div>
                            <div className="col-6">
                              <label className="form__label">
                                Invoice Number
                              </label>
                              <input
                                className={inputClassname}
                                type="text"
                                name="invoiceNumber"
                                value={invoiceNumber}
                                onChange={this.handleInputChange}
                                placeholder="Enter Invoice Number"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="textarea__box box-shadow--none w-50 p-0x">
                        <div className="form-group">
                          <label className="form__label">Notes</label>
                        </div>
                        <textarea
                          className="h-calc-50 font-secondary"
                          name="statementNotes"
                          value={statementNotes}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="actions d-flex justify-content-end gap-2x mb-3x">
                      <button
                        onClick={this.handleCancel}
                        disabled={!hasChange}
                        className="btn btn-link"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={!hasChange}
                        className="btn btn-primary has-loader"
                        onClick={this.handleSave}
                      >
                        Save Update
                        {isSaving && <span className="spinner" />}
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <EmptyProjectPage pageName="Statement" />
              )}

              {hasError && (
                <Toast
                  hasError={hasError}
                  title={errorMessage}
                  handleClose={this.resetError}
                />
              )}
            </div>
          </div>
        </main>
      </>
    );
  }
}

Statement.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  selectedProject: PropTypes.object,
  clearSelectedProject: PropTypes.func,
  updateSelectedProject: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
})(Statement);
