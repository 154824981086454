import { useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { colors } from 'constants/colors';

import { getClients } from 'services/client';

import Pill from 'components/common/pill/Pill';
import Icon from 'components/common/icons/Icons';
import Input from 'components/common/Input/Input';

import { useDebounce } from 'hooks/useDebounce';
import { getUserById } from 'services/users';
import Loader from 'components/common/loader/Loader';

// TODO: As a future enhancement, create a reusable component like AssignItems
const AssignClients = ({ setFormData, isEdit }) => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [selectedClients, setSelectedClients] = useState([]);

  const { userId } = useParams();

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery);

  // handle client selection
  const handleSelectClient = (client) => {
    setSelectedClients((prevState) => [...prevState, client]);
    setClients((prevState) => {
      return prevState.filter((c) => c.client_id !== client.client_id);
    });
    setFormData((prevState) => ({
      ...prevState,
      client_ids: [...prevState.client_ids, client.client_id].sort(),
    }));
  };

  // handle client removal
  const handleRemoveClient = (client) => {
    setClients((prevClients) => {
      // only add the client back to the clients array if it does not already exist
      if (!prevClients.some((c) => c.client_id === client.client_id)) {
        return [...prevClients, client];
      }

      return prevClients;
    });

    setSelectedClients((prevState) => {
      return prevState.filter((c) => c.client_id !== client.client_id);
    });
    setFormData((prevState) => ({
      ...prevState,
      client_ids: prevState.client_ids
        .filter((id) => id !== client.client_id)
        .sort(),
    }));
  };

  // Fetch and set clients
  useEffect(() => {
    setIsLoading(true);
    getClients({ name: debouncedSearchQuery })
      .then((clients) => {
        setClients(clients);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [debouncedSearchQuery]);

  useEffect(() => {
    setIsLoading(true);
    if (userId && isEdit) {
      // TODO: Use /users/:userId/clients once endpoint available
      getUserById(userId)
        .then((user) => {
          if (user.clients) {
            setSelectedClients(user.clients);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userId, isEdit]);

  // Memoize the filtered and sorted clients
  const { filteredClients, sortedSelectedClients } = useMemo(() => {
    const filteredClients = clients
      .filter(
        (c) =>
          !selectedClients.some(
            (selected) => selected.client_id === c.client_id
          )
      )
      .sort((a, b) => a.display_name.localeCompare(b.display_name));

    const sortedSelectedClients = [...selectedClients].sort((a, b) =>
      a.display_name.localeCompare(b.display_name)
    );

    return {
      filteredClients,
      sortedSelectedClients,
    };
  }, [clients, selectedClients]);

  return (
    <div className="px-6x mb-6x mt-6x">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="font-secondary color-primary--base">
          Assign Clients ({selectedClients.length})
        </h4>
        <div>
          <Input
            placeholder="Search Clients"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            rightElement={
              <div className="bg-base--white">
                <Icon icon="search" size={20} color={colors.grey[40]} />
              </div>
            }
          />
        </div>
      </div>
      <div className="my-6x">
        {selectedClients.length ? (
          <div>
            {sortedSelectedClients.map((client) => (
              <Pill
                label={client.display_name}
                key={client.client_id}
                className="mb-4x mr-4x"
                onRemove={() => handleRemoveClient(client)}
                isSelected
                labelClass="color-primary--base"
              />
            ))}
            <hr />
          </div>
        ) : null}
      </div>

      <div className="my-6x">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Loader />
          </div>
        ) : (
          filteredClients.map((client) => (
            <Pill
              label={client.display_name}
              key={client.client_id}
              className="mb-4x mr-4x"
              onClick={() => handleSelectClient(client)}
              labelClass="color-primary--base"
            />
          ))
        )}
      </div>
    </div>
  );
};

AssignClients.defaultProps = {
  isEdit: false,
};

AssignClients.propTypes = {
  setFormData: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default AssignClients;
