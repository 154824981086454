import React from 'react';
import PropTypes from 'prop-types';
import { formatAnyValue } from 'utils/common/formatter';

const StatCard = (props) => {
  const { data, cardConfig } = props;

  const listItems = Object.keys(data)
    .filter((dataKey) => {
      return (
        dataKey !== null &&
        dataKey !== undefined &&
        cardConfig[dataKey] !== null &&
        cardConfig[dataKey] !== undefined &&
        cardConfig[dataKey].label !== null &&
        cardConfig[dataKey].label !== undefined
      );
    })
    .map((dataKey, index) => {
      const label = cardConfig[dataKey].label;
      const format = cardConfig[dataKey].format;
      const value = data[dataKey] ? formatAnyValue(format, data[dataKey]) : '';

      return (
        <li className="stat__list__item" key={index}>
          <span className="stat__list__label">{label}</span>
          <span className="stat__list__value">{value}</span>
        </li>
      );
    });

  return (
    <div className="stat__card">
      <div className="stat__card__header">
        <h4>{props.title}</h4>
      </div>
      <div className="stat__card__body">
        <ul className="stat__lists">{listItems}</ul>
      </div>
    </div>
  );
};

StatCard.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  cardConfig: PropTypes.object,
};
export default StatCard;
