import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './login/Login';
import HomePage from './home/Home';
import ProtectedRoute from './common/ProtectedRoute';

import QA from './qa/QA';
import Jobs from './jobs/Jobs';
import Users from './users/Users';
import Mapping from './mapping/Mapping';
import { AddUser } from './userManagement/AddUser';
import QADetails from './qaDetails/QADetails';
import Statement from './statement/Statement';
import Publisher from './publisher/Publisher';
import EditUser from './userManagement/EditUser';
import Algorithms from './algorithms/Algorithms';
import DataChooser from './dataChooser/DataChooser';
import RuleLibrary from './ruleLibrary/RuleLibrary';
import PageNotFound from './common/emptypage/PageNotFound';
import UserManagement from './userManagement/UserManagement';
import ProjectAnalysis from './projectAnalysis/ProjectAnalysis';
import InsuranceMapping from './insuranceMapping/InsuranceMapping';
import ValidationChecker from './validationChecker/ValidationChecker';
import TransactionMapping from './transactionMapping/TransactionMapping';
import AccountInformation from './accountInformation/AccountInformation';
import AccountInformationWithLink from './accountInformation/AccountInformationWithLink';

import * as routes from 'constants/routes';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.LOGIN} element={<Login />} />
        <Route
          path={routes.INSURANCE_MAPPING}
          element={
            <ProtectedRoute>
              <InsuranceMapping />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.TRANSACTION_MAPPING}
          element={
            <ProtectedRoute>
              <TransactionMapping />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.ALGORITHMS}
          element={
            <ProtectedRoute>
              <Algorithms />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.ACCOUNT_INFORMATION}
          element={
            <ProtectedRoute>
              <AccountInformation />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.ACCOUNT_INFORMATION_WITH_ACCOUNT_NO}
          element={
            <ProtectedRoute>
              <AccountInformationWithLink />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.JOBS}
          element={
            <ProtectedRoute>
              <Jobs />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.USER_MANAGEMENT}
          element={
            <ProtectedRoute>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.ADD_USER}
          element={
            <ProtectedRoute>
              <AddUser />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.EDIT_USER}
          element={
            <ProtectedRoute>
              <EditUser />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.PROJECT_ANALYSIS}
          element={
            <ProtectedRoute>
              <ProjectAnalysis />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.DATA_CHOOSER}
          element={
            <ProtectedRoute>
              <DataChooser />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.VALIDATION_CHECKER}
          element={
            <ProtectedRoute>
              <ValidationChecker />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.MAPPING}
          element={
            <ProtectedRoute>
              <Mapping />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.RULE_LIBRARY}
          element={
            <ProtectedRoute>
              <RuleLibrary />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.QA}
          element={
            <ProtectedRoute>
              <QA />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.QA_DETAILS}
          element={
            <ProtectedRoute>
              <QADetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.PUBLISHER}
          element={
            <ProtectedRoute>
              <Publisher />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.STATEMENT}
          element={
            <ProtectedRoute>
              <Statement />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.HOME}
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.USERS}
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <PageNotFound />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
