import { customFormatTypes } from 'constants/formatConfig';
import {
  DATE_SORTER,
  NUMERIC_SORTER,
  AMOUNT_SORTER,
} from 'components/common/table/constants';

import { customNegativeValueColorFormatter } from 'components/common/table/CustomNegativeValueColorFormatter';

export const POST_DATE_FIELD = 'post_date';

export const financialsColumnConfig = [
  {
    field: 'account_financial_id',
    title: 'ID',
    isShownByDefault: false,
    isEditable: false,
    sorter: NUMERIC_SORTER,
  },
  {
    field: 'client_id',
    title: 'Client ID',
    isShownByDefault: false,
    isEditable: false,
    sorter: NUMERIC_SORTER,
  },
  {
    field: 'account_id',
    title: 'Account Id',
    isShownByDefault: false,
    isEditable: false,
    sorter: NUMERIC_SORTER,
  },
  {
    field: 'account_number',
    title: 'Account Number',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'transaction_type_code',
    title: 'Type Code',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'transaction_type_name',
    title: 'Type Desc',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'transaction_code',
    title: 'Trans Code',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'transaction_code_name',
    title: 'Trans Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'category_name',
    title: 'CH TX Cat',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      cssClass: 'highlight-col',
    },
  },
  {
    field: 'is_payment',
    title: 'Payment',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.boolean,
  },
  {
    field: 'is_reversal',
    title: 'Reversal',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.boolean,
  },
  {
    field: 'cob',
    title: 'COB',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.noFormat,
  },
  {
    field: POST_DATE_FIELD,
    title: 'Post Date',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
  },
  {
    field: 'financial_amount',
    title: 'Amount',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.currency,
    customSorter: AMOUNT_SORTER,
    additionalConfig: {
      formatter: customNegativeValueColorFormatter,
    },
  },
  {
    field: 'deductible_amount',
    title: 'Deductible',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.currency,
    customSorter: AMOUNT_SORTER,
    additionalConfig: {
      formatter: customNegativeValueColorFormatter,
    },
  },
  {
    field: 'copay_amount',
    title: 'Copay',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.currency,
    customSorter: AMOUNT_SORTER,
    additionalConfig: {
      formatter: customNegativeValueColorFormatter,
    },
  },
  {
    field: 'coinsurance_amount',
    title: 'Coins',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.currency,
    customSorter: AMOUNT_SORTER,
    additionalConfig: {
      formatter: customNegativeValueColorFormatter,
    },
  },
  {
    field: 'nrp',
    title: 'NRP',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.currency,
    additionalConfig: {
      formatter: customNegativeValueColorFormatter,
    },
  },
  {
    field: 'insurance_plan_code',
    title: 'Plan Code',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_plan_name',
    title: 'Plan Name',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_payor_code',
    title: 'Ins Code',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_payor_name',
    title: 'Ins Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'insurance_financial_class_code',
    title: 'FC Code',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_financial_class_name',
    title: 'FC Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'insurance_category_name',
    title: 'CH Ins Cat',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      cssClass: 'highlight-col',
    },
  },
  {
    field: 'transaction_id',
    title: 'Transaction Id',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'transaction_note',
    title: 'Note',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'user_code',
    title: 'User',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'create_date',
    title: 'Create Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
  },
  {
    field: 'update_date',
    title: 'Update Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
  },
];

export const billReferenceColumnConfig = [
  {
    field: 'client_id',
    title: 'Client Id',
    sorter: NUMERIC_SORTER,
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'account_id',
    title: 'Account Id',
    sorter: NUMERIC_SORTER,
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'account_billreference_id',
    title: 'Id',
    sorter: NUMERIC_SORTER,
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'bucket_status',
    title: 'Status',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'bucket_type',
    title: 'Type',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'cob',
    title: 'COB',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'insurance_plan_code',
    title: 'Plan Code',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_plan_name',
    title: 'Plan Name',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_payor_code',
    title: 'Ins Code',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_payor_name',
    title: 'Ins Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'insurance_financial_class_code',
    title: 'FC Code',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_financial_class_name',
    title: 'FC Name',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      cssClass: 'highlight-col',
    },
  },
  {
    field: 'ch_insurance_category',
    title: 'Category',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'latest_invoice_number',
    title: 'Invoice Number',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'last_payment_date',
    title: 'Last Pay Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
  },
  {
    field: 'first_claim_date',
    title: 'First Claim Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
  },
  {
    field: 'last_claim_date',
    title: 'Last Claim Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
  },
  {
    field: 'total_charges',
    title: 'Total Charges',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.currencyWithoutSign,
    customSorter: AMOUNT_SORTER,
  },
  {
    field: 'total_payments',
    title: 'Total Payments',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.currencyWithoutSign,
    customSorter: AMOUNT_SORTER,
  },
  {
    field: 'total_adjustments',
    title: 'Total Adjustments',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.currencyWithoutSign,
    customSorter: AMOUNT_SORTER,
  },
  {
    field: 'total_balance',
    title: 'Balance',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.currencyWithoutSign,
    customSorter: AMOUNT_SORTER,
  },
  {
    field: 'last_payment_amount',
    title: 'Last Pay Amount',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.currencyWithoutSign,
    customSorter: AMOUNT_SORTER,
  },
  {
    field: 'create_date',
    title: 'Create Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
  },
  {
    field: 'update_date',
    title: 'Update Date',
    isShownByDefault: false,
    isEditable: false,
    formatOfData: customFormatTypes.date,
    customSorter: DATE_SORTER,
  },
];

export const insuranceTableColumnConfig = [
  {
    field: 'account_insurance_id',
    title: 'Id',
    sorter: NUMERIC_SORTER,
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'client_id',
    title: 'Client Id',
    sorter: NUMERIC_SORTER,
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'account_id',
    title: 'Account Id',
    sorter: NUMERIC_SORTER,
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_id',
    title: 'Insurance Id',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'ch_insurance_category',
    title: 'Category',
    isShownByDefault: true,
    isEditable: false,
    additionalConfig: {
      cssClass: 'highlight-col',
    },
  },
  {
    field: 'insurance_position',
    title: 'COB',
    isShownByDefault: true,
    isEditable: false,
    formatOfData: customFormatTypes.noFormat,
  },
  {
    field: 'insurance_plan_code',
    title: 'Plan Code',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_plan_name',
    title: 'Plan Name',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'insurance_payor_code',
    title: 'Ins Code',
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'insurance_payor_name',
    title: 'Ins Name',
    isShownByDefault: true,
    isEditable: false,
  },
];

export const dataIntegrityColumnConfig = [
  {
    field: 'project_validation_id',
    title: 'ID',
    isShownByDefault: false,
    sorter: NUMERIC_SORTER,
    isEditable: false,
  },
  {
    field: 'project_validation_check_id',
    title: 'Check ID',
    isShownByDefault: false,
    sorter: NUMERIC_SORTER,
    isEditable: false,
  },
  {
    field: 'priority',
    title: 'Rank',
    sorter: NUMERIC_SORTER,
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'check_category',
    title: 'Category',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'check_name',
    title: 'Name',
    isShownByDefault: true,
    isEditable: false,
    tooltip: true,
  },
];

export const ruleHitsColumnConfig = [
  {
    field: 'project_report_id',
    title: 'ID',
    sorter: NUMERIC_SORTER,
    formatOfData: customFormatTypes.noFormat,
    isShownByDefault: false,
    isEditable: false,
  },
  {
    field: 'rule_base_id',
    title: 'Rule ID',
    sorter: NUMERIC_SORTER,
    formatOfData: customFormatTypes.noFormat,
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'priority',
    title: 'Priority',
    sorter: NUMERIC_SORTER,
    isShownByDefault: true,
    formatOfData: customFormatTypes.noFormat,
    isEditable: false,
  },
  {
    field: 'rule_category',
    title: 'Category',
    isShownByDefault: true,
    isEditable: false,
  },
  {
    field: 'rule_name',
    title: 'Rule',
    isShownByDefault: true,
    isEditable: false,
    tooltip: true,
  },
  {
    field: 'rule_state',
    title: 'State',
    formatOfData: customFormatTypes.state,
    isShownByDefault: true,
    isEditable: false,
  },
];
