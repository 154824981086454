import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Tag from 'components/common/tag/Tag';
import Table from 'components/common/table/Table';
import CustomColumnSelector from 'components/common/table/CustomColumnSelector';

import { dataIntegrityTableConfig } from './configFiles/tableConfigs';
import { dataIntegrityColumnConfig } from './configFiles/columnConfigs';

import { getDataIntegrityData } from 'services/accountInformation';

const mapStateToProps = (state) => {
  const { selectedClientId, accountNumber } = state;

  return { selectedClientId, accountNumber };
};

class DataIntegrityTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
      filteredFields: [],
      selectedColumns: [],
      updatedColumnConfig: {
        field: '',
        isShownByDefault: false,
      },
    };

    this.tableRef = React.createRef(null);
  }

  async componentDidMount() {
    await this.updateTableData();

    this.setState({
      selectedColumns: dataIntegrityColumnConfig
        .filter((col) => col.isShownByDefault)
        .map((col) => col.field),
    });
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.shouldFetchNewData && this.props.shouldFetchNewData) {
      await this.updateTableData();
    }
  }

  updateTableData = async () => {
    const { accountNumber, selectedClientId } = this.props;
    this.setState({
      isLoading: true,
      data: [],
    });

    try {
      const data = await getDataIntegrityData(accountNumber, selectedClientId);

      this.setState({
        isLoading: false,
        data,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        data: [],
      });
    }
  };

  handleCategorySelect = (selectedColumnField) => {
    const { selectedColumns } = this.state;
    const selected = selectedColumns.includes(selectedColumnField);

    if (selected) {
      this.setState({
        selectedColumns: selectedColumns.filter(
          (col) => col !== selectedColumnField
        ),
      });
    }

    if (!selected) {
      this.setState({
        selectedColumns: [...selectedColumns, selectedColumnField],
      });
    }

    this.setState({
      updatedColumnConfig: {
        field: selectedColumnField,
        isShownByDefault: !selected,
      },
    });
  };

  handleDataFiltered = (filters) => {
    const filteredFields = filters.map((filter) => {
      const foundConfig = dataIntegrityColumnConfig.find(
        (config) => config.field === filter.field
      );

      if (!foundConfig && foundConfig.title) {
        return '';
      }

      return foundConfig.title;
    });

    this.setState({
      filteredFields,
    });
  };

  handleResetFilters = () => {
    const { tabulator } = this.tableRef.current;

    tabulator.clearHeaderFilter();
  };

  render() {
    const { data, selectedColumns, updatedColumnConfig, filteredFields } =
      this.state;

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-2x">
          <h4 className="color-primary--base">Data Integrity</h4>
          <Tag
            maxWidthInPx={400}
            tags={filteredFields}
            resetTags={this.handleResetFilters}
          />
          <CustomColumnSelector
            columnConfig={dataIntegrityColumnConfig}
            handleSelect={this.handleCategorySelect}
            selectedColumns={selectedColumns}
          />
        </div>
        <Table
          ref={this.tableRef}
          className={'has-box-shadow'}
          tableConfig={dataIntegrityTableConfig}
          columnConfig={dataIntegrityColumnConfig}
          data={data}
          onDataFiltered={this.handleDataFiltered}
          selectedColumns={selectedColumns}
          updatedColumnConfig={updatedColumnConfig}
        />
      </>
    );
  }
}

DataIntegrityTable.propTypes = {
  shouldFetchNewData: PropTypes.bool,
  accountNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {})(DataIntegrityTable);
