import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Tag from 'components/common/tag/Tag';
import Icon from 'components/common/icons/Icons';
import Table from 'components/common/table/Table';
import SwitchButton from 'components/common/switch/SwitchButton';
import CustomColumnSelector from 'components/common/table/CustomColumnSelector';

import {
  ACCOUNT_INFO_NO_CLIENT_SELECTED,
  ACCOUNT_INFO_NO_ACCOUNT_NUMBER,
  DEFAULT_ERROR_MESSAGE,
} from 'constants/errorMessages';

import { financialsTableConfig } from './configFiles/tableConfigs';
import { financialsColumnConfig } from './configFiles/columnConfigs';

import { getAccountFinancialsData } from 'services/accountInformation';

const mapStateToProps = (state) => {
  const { selectedClientId, accountNumber } = state;

  return { selectedClientId, accountNumber };
};

class AccountFinancialsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredFields: [],
      selectedColumns: [],
      onlyTruePaymentsCount: 0,
      onlyTrueReversalCount: 0,
      showOnlyTruePayments: true,
      showOnlyTrueReversal: true,
      updatedColumnConfig: {
        field: '',
        isShownByDefault: false,
      },
    };

    this.tableRef = React.createRef(null);
  }

  async componentDidMount() {
    await this.updateTableData();

    this.setState({
      selectedColumns: financialsColumnConfig
        .filter((col) => col.isShownByDefault)
        .map((col) => col.field),
    });
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.shouldFetchNewData && this.props.shouldFetchNewData) {
      await this.updateTableData();
    }
  }

  updateTableData = async () => {
    this.setState({
      data: [],
      hasError: false,
      errorMessage: '',
    });
    const { selectedClientId, accountNumber } = this.props;

    if (!selectedClientId) {
      this.props.handleError(ACCOUNT_INFO_NO_CLIENT_SELECTED);

      return;
    }

    if (!accountNumber) {
      this.props.handleError(ACCOUNT_INFO_NO_ACCOUNT_NUMBER);

      return;
    }

    try {
      const data = await getAccountFinancialsData(
        accountNumber,
        selectedClientId
      );
      const dataWithAccountNumber = data.map((row) => {
        row.account_number = accountNumber;

        return row;
      });

      this.setState({
        data: dataWithAccountNumber,
        hasError: false,
        errorMessage: '',
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        data: [],
      });

      this.props.handleError(errorMessage);
    }
  };

  handleCategorySelect = (selectedColumnField) => {
    const { selectedColumns } = this.state;
    const selected = selectedColumns.includes(selectedColumnField);

    if (selected) {
      this.setState({
        selectedColumns: selectedColumns.filter(
          (col) => col !== selectedColumnField
        ),
      });
    }

    if (!selected) {
      this.setState({
        selectedColumns: [...selectedColumns, selectedColumnField],
      });
    }

    this.setState({
      updatedColumnConfig: {
        field: selectedColumnField,
        isShownByDefault: !selected,
      },
    });
  };

  getFilteredData = () => {
    const { data, showOnlyTruePayments, showOnlyTrueReversal } = this.state;

    return data.filter((row) => {
      if (showOnlyTrueReversal && showOnlyTruePayments) {
        return row.is_reversal && row.is_payment;
      }
      if (showOnlyTruePayments) {
        return row.is_payment;
      }

      if (showOnlyTrueReversal) {
        return row.is_reversal;
      }

      return true;
    });
  };

  handleOnlyTruePaymentsToggle = () => {
    this.setState({
      showOnlyTruePayments: !this.state.showOnlyTruePayments,
    });
  };

  handleOnlyTrueReversalsToggle = () => {
    this.setState({
      showOnlyTrueReversal: !this.state.showOnlyTrueReversal,
    });
  };

  handleDataFiltered = (filters, rows) => {
    const filteredFields = filters.map((filter) => {
      const foundConfig = financialsColumnConfig.find(
        (config) => config.field === filter.field
      );

      if (!foundConfig && foundConfig.title) {
        return '';
      }

      return foundConfig.title;
    });

    const { onlyTruePaymentsCount, onlyTrueReversalCount } = rows.reduce(
      (acc, row) => {
        const rowData = row.getData();

        if (rowData.is_payment) {
          acc.onlyTruePaymentsCount += 1;
        }

        if (rowData.is_reversal) {
          acc.onlyTrueReversalCount += 1;
        }

        return acc;
      },

      { onlyTruePaymentsCount: 0, onlyTrueReversalCount: 0 }
    );

    this.setState({
      filteredFields,
      onlyTruePaymentsCount,
      onlyTrueReversalCount,
    });
  };

  handleResetFilters = () => {
    const { tabulator } = this.tableRef.current;

    tabulator.clearHeaderFilter();
  };

  render() {
    const {
      filteredFields,
      selectedColumns,
      updatedColumnConfig,
      showOnlyTruePayments,
      showOnlyTrueReversal,
      onlyTruePaymentsCount,
      onlyTrueReversalCount,
    } = this.state;

    const tableData = this.getFilteredData();

    return (
      <>
        <div className="d-flex justify-content-between align-items-end mb-4x">
          <h4 className="color-primary--base line-height-1">
            Account Financials
          </h4>
        </div>

        <div className="d-flex justify-content-between align-items-center bg-white--base py-2x px-4x mb-1x border-radius-4">
          <div className="switcher-filters">
            <SwitchButton
              key={'OnlyTruePayments'}
              labelText={'Payments'}
              value={showOnlyTruePayments}
              badgeCount={onlyTruePaymentsCount}
              onClick={this.handleOnlyTruePaymentsToggle}
            />

            <SwitchButton
              key={'OnlyTrueReversal'}
              labelText={'Reversal'}
              value={showOnlyTrueReversal}
              badgeCount={onlyTrueReversalCount}
              onClick={this.handleOnlyTrueReversalsToggle}
            />
          </div>
          <div className="d-flex gap-9x">
            <Tag
              maxWidthInPx={800}
              tags={filteredFields}
              resetTags={this.handleResetFilters}
            />
            <CustomColumnSelector
              columnConfig={financialsColumnConfig}
              handleSelect={this.handleCategorySelect}
              selectedColumns={selectedColumns}
            />
            <button
              className="btn-ghost px-2x py-1x"
              onClick={this.props.exportTable}
            >
              <Icon
                icon="export"
                className="mr-2x icon-export"
                width={10}
                height={14}
                color="#DADADA"
              />
              Export
            </button>
          </div>
        </div>

        <div className="table mb-5x">
          <Table
            ref={this.tableRef}
            data={tableData}
            onDataFiltered={this.handleDataFiltered}
            columnConfig={financialsColumnConfig}
            selectedColumns={selectedColumns}
            tableConfig={financialsTableConfig}
            updatedColumnConfig={updatedColumnConfig}
          />
        </div>
      </>
    );
  }
}

AccountFinancialsTable.propTypes = {
  handleError: PropTypes.func,
  exportTable: PropTypes.func,
  shouldFetchNewData: PropTypes.bool,
  columnConfig: PropTypes.arrayOf(PropTypes.object),
  accountTableData: PropTypes.arrayOf(PropTypes.object),
  accountColumnConfig: PropTypes.arrayOf(PropTypes.object),
  accountNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {})(AccountFinancialsTable);
