import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Input = ({
  value,
  onChange,
  onBlur,
  error,
  label,
  placeholder,
  name,
  type,
  rightElement,
  required,
  autoComplete,
}) => {
  return (
    <div>
      <div className="form-group">
        {label && (
          <label className="form__label">
            {label}
            {required && '*'}
          </label>
        )}
        <div className="d-flex align-items-center">
          <input
            className={classNames('form__control', {
              'user-form__error': error,
            })}
            type={type}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            autoComplete={autoComplete}
          />
          <div className="input-right">{rightElement}</div>
        </div>
        {error && <p className="user-form__error-msg text-sm mt-1x">{error}</p>}
      </div>
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  name: '',
  value: '',
  autoComplete: 'off',
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  rightElement: PropTypes.element,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
};

export default Input;
