import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import AlgorithmModal from './Modal';
import Tag from 'components/common/tag/Tag';
import Sidebar from 'components/sidebar/Sidebar';
import Table from 'components/common/table/Table';
import Toast from 'components/common/toast/Toast';
import Modal from 'components/common/modal/Modal';
import Loader from 'components/common/loader/Loader';
import Dropdown from 'components/common/dropdown/Dropdown';
import EmptyPage from 'components/common/emptypage/EmptyPage';
import CustomColumnSelector from 'components/common/table/CustomColumnSelector';

import { USERNAME } from 'constants/storage';
import { algorithmFormInputs } from 'constants/requiredFormInputs';
import { EDIT_BUTTON_COLUMN } from 'components/common/table/constants';
import { DEFAULT_ERROR_MESSAGE, MISSING_INPUT } from 'constants/errorMessages';

import { tableConfig } from './tableConfig';
import { columnConfig, RULE_BASE_ID } from './columnConfig';

import { getAllClients } from 'services/client';
import {
  getAlgorithms,
  getAlgorithmsCategories,
  updateRuleBaseById,
  addNewRuleBase,
} from 'services/algorithms';

import { updateClientID, updateClientList } from 'actions/clientAction';

import * as storage from 'utils/storage';
import { findMissingInput } from 'utils/payload';
import { interpolate } from 'utils/common/string';

const mapStateToProps = (state) => {
  const { selectedClientId, clientList } = state;

  return { selectedClientId, clientList };
};

class Algorithms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isSaving: false,
      selectedRow: '',
      hasError: false,
      errorMessage: '',
      categoryList: [],
      isLoading: false,
      isEditing: false,
      hasNewData: false,
      filteredFields: [],
      selectedColumns: [],
      isSubmitting: false,
      hasUnsavedChanges: false,
      ruleDefinition: '',
      isShowingModal: false,
      selectedRuleBaseId: null,
      modalData: {
        name: '',
        state: '',
        category: {},
        priority: '',
        isEditing: false,
        ruleDefinition: '',
      },
      updatedColumnConfig: {
        field: '',
        isShownByDefault: false,
      },
    };

    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({
      selectedColumns: columnConfig
        .filter(
          (config) =>
            config.isShownByDefault && config.field !== EDIT_BUTTON_COLUMN
        )
        .map((col) => col.field),
    });

    await this.getAlgorithms();
    await this.getCategoryList();

    if (this.props.clientList.length === 0) {
      await this.updateClientList();
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedClientId !== this.props.selectedClientId) {
      await this.getAlgorithms();
    }
  }

  updateClientList = async () => {
    const data = await getAllClients();

    if (data) {
      this.props.updateClientList(data);
    }
  };

  openAddNewModal = () => {
    this.clearModal();
    this.setState({
      isEditing: false,
      isShowingModal: true,
      hasUnsavedChanges: false,
    });
  };

  handleCloseModal = () => {
    this.setState({
      hasNewData: false,
      isShowingModal: false,
    });
    this.clearModal();
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.setState({
      ruleDefinition: '',
    });

    this.props.updateClientID(clientId);
  };

  handleModalEdit = (event) => {
    const { name, value } = event.target;

    this.setState({
      hasNewData: false,
      modalData: {
        ...this.state.modalData,
        [name]: value,
      },
    });
  };

  clearModal = () => {
    this.setState({
      modalData: {
        name: '',
        state: '',
        category: '',
        priority: '',
        isEditing: false,
        ruleDefinition: '',
      },
    });
  };

  openEditModal = async (_, cell) => {
    const rowData = cell.getRow().getData();

    const category = this.state.categoryList.find(
      (cat) =>
        cat.label === rowData.rule_category ||
        cat.value === rowData.rule_category
    );

    this.setState({
      isEditing: true,
      isShowingModal: true,
      hasUnsavedChanges: false,
      selectedRuleBaseId: rowData[RULE_BASE_ID],
      modalData: {
        isEditing: true,
        category: category,
        name: rowData.rule_name,
        state: rowData.rule_state,
        priority: rowData.priority,
        ruleDefinition: rowData.rule_definition,
      },
    });
  };

  handleRowClick = (_, row) => {
    const rowData = row.getData();

    this.setState({
      hasUnsavedChanges: false,
      ruleDefinition: rowData.rule_definition || '',
      selectedRuleBaseId: rowData[RULE_BASE_ID],
      selectedRow: rowData[RULE_BASE_ID],
    });
  };

  updateRuleDefinition = (event) => {
    this.setState({
      hasNewData: false,
      hasUnsavedChanges: true,
      ruleDefinition: event.target.value,
    });
  };

  getAlgorithms = async () => {
    const { selectedClientId } = this.props;
    if (!selectedClientId) {
      return;
    }

    this.setState({
      isLoading: true,
      hasError: false,
      errorMessage: '',
    });

    try {
      const data = await getAlgorithms(selectedClientId);

      this.setState({
        data: data,
        isLoading: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
        hasNewData: false,
      });
    }
  };

  handleSave = async () => {
    const { modalData } = this.state;
    const apiData = {
      clientId: parseInt(this.props.selectedClientId),
      name: modalData.name,
      category: modalData.category.value,
      state: modalData.state,
      ruleDefinition: modalData.ruleDefinition,
      priority: parseInt(modalData.priority),
      version: 1,
      updateUser: storage.get(USERNAME),
    };

    const missingInput = findMissingInput(apiData, algorithmFormInputs);
    if (missingInput) {
      this.setState({
        hasError: true,
        errorMessage: interpolate(MISSING_INPUT, { name: missingInput }),
      });

      return;
    }

    this.setState({
      isSaving: true,
      hasError: false,
      errorMessage: '',
    });

    try {
      await addNewRuleBase(apiData);

      const data = await getAlgorithms(this.props.selectedClientId);

      this.setState({
        data: data,
        isSaving: false,
        hasNewData: true,
        isShowingModal: false,
        hasUnsavedChanges: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isSaving: false,
        hasNewData: false,
        isShowingModal: false,
      });
    }

    this.clearModal();
  };

  handleUpdateBtnClick = async () => {
    const { selectedRuleBaseId, ruleDefinition, isShowingModal, modalData } =
      this.state;

    const newRuleDefinition = isShowingModal
      ? modalData.ruleDefinition
      : ruleDefinition;

    const apiData = {
      clientId: parseInt(this.props.selectedClientId),
      name: modalData.name,
      category: modalData.category.value || '',
      state: modalData.state,
      ruleDefinition: newRuleDefinition,
      priority: parseInt(modalData.priority),
      updateUser: storage.get(USERNAME),
    };

    if (isShowingModal) {
      const missingInput = findMissingInput(apiData, algorithmFormInputs);
      if (missingInput) {
        this.setState({
          hasError: true,
          errorMessage: interpolate(MISSING_INPUT, { name: missingInput }),
        });

        return;
      }
    }

    this.setState({
      isSubmitting: true,
    });

    try {
      await updateRuleBaseById(selectedRuleBaseId, apiData);

      const data = await getAlgorithms(this.props.selectedClientId);

      this.setState({
        data: data,
        isEditing: false,
        hasNewData: true,
        isSubmitting: false,
        isShowingModal: false,
        hasUnsavedChanges: false,
        ruleDefinition: isShowingModal
          ? modalData.ruleDefinition
          : ruleDefinition,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        hasNewData: false,
        isSubmitting: false,
        isShowingModal: false,
      });
    }
  };

  handleResetBtnClick = () => {
    const { data } = this.state;

    let actualDefinition = data.find(
      (rule) =>
        parseInt(rule[RULE_BASE_ID]) === parseInt(this.state.selectedRuleBaseId)
    );

    this.setState({
      hasUnsavedChanges: true,
      ruleDefinition: actualDefinition.rule_definition || '',
    });
  };

  getCategoryList = async () => {
    try {
      const data = await getAlgorithmsCategories();

      this.setState({
        fetchedCategories: data,
        categoryList: data.map((category) => {
          return {
            label: category.dictionary_name,
            value: category.dictionary_value,
          };
        }),
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
      });
    }
  };

  handleSelect = (selectedCategory) => {
    this.setState({
      hasNewData: false,
      modalData: {
        ...this.state.modalData,
        category: selectedCategory,
      },
    });
  };

  handleCategorySelect = (selectedColumnField) => {
    const { selectedColumns } = this.state;
    const selected = selectedColumns.includes(selectedColumnField);

    if (selected) {
      this.setState({
        selectedColumns: selectedColumns.filter(
          (col) => col !== selectedColumnField
        ),
      });
    }

    if (!selected) {
      this.setState({
        selectedColumns: [...selectedColumns, selectedColumnField],
      });
    }

    this.setState({
      updatedColumnConfig: {
        field: selectedColumnField,
        isShownByDefault: !selected,
      },
    });
  };

  handleDataFiltered = (filters) => {
    const filteredFields = filters.map((filter) => {
      const foundConfig = columnConfig.find(
        (config) => config.field === filter.field
      );

      if (!foundConfig && foundConfig.title) {
        return '';
      }

      return foundConfig.title;
    });

    this.setState({
      filteredFields,
    });
  };

  handleResetFilters = () => {
    const { tabulator } = this.tableRef.current;

    tabulator.clearHeaderFilter();
  };

  handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(this.state.ruleDefinition);
    } catch (error) {
      this.setState({
        hasError: true,
        errorMessage: DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  resetError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      data,
      hasError,
      isLoading,
      modalData,
      hasNewData,
      selectedRow,
      errorMessage,
      categoryList,
      isSubmitting,
      isShowingModal,
      ruleDefinition,
      filteredFields,
      selectedColumns,
      hasUnsavedChanges,
      selectedRuleBaseId,
      updatedColumnConfig,
    } = this.state;
    const { clientList, selectedClientId } = this.props;

    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );
    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });

    const componentClassName = classNames({
      'd-flex': true,
      'empty-page': !selectedClientId,
    });

    return (
      <main>
        <div className={componentClassName}>
          <Sidebar />
          <div className="main-content px-5x pb-5x d-flex flex-direction-column">
            <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
              <div className="profile mb-3x ml-auto">
                <Dropdown
                  label={displayName}
                  dropdownItems={dropdownItems}
                  onClick={this.handleSelectClient}
                />
              </div>
              <div className="d-flex justify-content-between align-items-end mb-3x">
                <h1>Algorithms</h1>

                <Tag
                  maxWidthInPx={800}
                  tags={filteredFields}
                  resetTags={this.handleResetFilters}
                />

                {selectedClientId ? (
                  <CustomColumnSelector
                    className={'dropdown__right'}
                    columnConfig={columnConfig}
                    handleSelect={this.handleCategorySelect}
                    selectedColumns={selectedColumns}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>

            {selectedClientId ? (
              <>
                {isLoading && <Loader isFullScreen={true} />}
                <div
                  className="d-flex flex-direction-column"
                  style={{ height: 'calc(100vh - 143px)' }}
                >
                  <div className="table table-25vh has-box-shadow">
                    <Table
                      className="has-box-shadow"
                      data={data}
                      ref={this.tableRef}
                      hasNewData={hasNewData}
                      selectedRow={selectedRow}
                      tableConfig={tableConfig}
                      columnConfig={columnConfig}
                      onRowClick={this.handleRowClick}
                      selectedColumns={selectedColumns}
                      onDataFiltered={this.handleDataFiltered}
                      updatedColumnConfig={updatedColumnConfig}
                      handleEditButtonClick={this.openEditModal}
                    />
                  </div>

                  <div className="d-flex justify-content-end align-items-center my-3x">
                    <button
                      className="btn btn-primary"
                      onClick={this.openAddNewModal}
                    >
                      Add New Algorithm
                    </button>
                  </div>

                  <div className="textarea__box">
                    <div className="d-flex justify-content-between align-items-center my-3x">
                      <h4 className="title-18">Rule Definition</h4>
                      {selectedRuleBaseId && (
                        <button
                          className="btn btn-primary"
                          onClick={this.handleCopy}
                        >
                          Copy
                        </button>
                      )}
                    </div>
                    {selectedRuleBaseId && (
                      <>
                        <textarea
                          className=""
                          value={ruleDefinition}
                          onChange={this.updateRuleDefinition}
                        />

                        <div className="actions d-flex justify-content-end mt-4x">
                          <button
                            className="btn btn-link"
                            onClick={this.handleResetBtnClick}
                            disabled={!hasUnsavedChanges}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-primary has-loader"
                            onClick={this.handleUpdateBtnClick}
                            disabled={!hasUnsavedChanges}
                          >
                            Update{' '}
                            {isSubmitting && !isShowingModal && (
                              <span className="spinner" />
                            )}
                          </button>
                        </div>
                      </>
                    )}
                    {!selectedClientId && (
                      <EmptyPage
                        sectionMessage={true}
                        sectionParameters="any row"
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <EmptyPage
                selectionParameters={'client'}
                pageName={'algorithms'}
              />
            )}

            {isShowingModal && (
              <Modal onClose={this.handleCloseModal}>
                <AlgorithmModal
                  {...modalData}
                  open={isShowingModal}
                  onSave={this.handleSave}
                  categoryList={categoryList}
                  onClose={this.handleCloseModal}
                  handleSelect={this.handleSelect}
                  handleChange={this.handleModalEdit}
                  onUpdate={this.handleUpdateBtnClick}
                  isSaving={isSubmitting && isShowingModal}
                />
              </Modal>
            )}

            {hasError && (
              <Toast
                hasError={hasError}
                title={errorMessage}
                handleClose={this.resetError}
              />
            )}
          </div>
        </div>
      </main>
    );
  }
}

Algorithms.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateClientList,
})(Algorithms);
