export const HOME = '/';
export const QA = '/qa';
export const MFA = '/mfa';
export const JOBS = '/jobs';
export const LOGIN = '/login';
export const USERS = '/users';
export const MAPPING = '/mapping';
export const ADD_USER = '/add_user';
export const PUBLISHER = '/publisher';
export const STATEMENT = '/statement';
export const ALGORITHMS = '/algorithms';
export const QA_DETAILS = '/qa_details';
export const DATA_CHOOSER = '/data_chooser';
export const RULE_LIBRARY = '/rule_library';
export const ACCOUNT_INFORMATION_WITH_ACCOUNT_NO =
  '/account_information/:clientId/:accountNumber';
export const USER_MANAGEMENT = '/user_management';
export const EDIT_USER = '/user_management/:userId/edit';
export const PROJECT_ANALYSIS = '/project_analysis';
export const INSURANCE_MAPPING = '/insurance_mapping';
export const VALIDATION_CHECKER = '/validation_checker';
export const TRANSACTION_MAPPING = '/transaction_mapping';
export const ACCOUNT_INFORMATION = '/account_information';
