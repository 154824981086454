import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { colors } from 'constants/colors';
import { USER_MANAGEMENT } from 'constants/routes';
import { USER_FORM_FIELDS } from 'constants/userManagement';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

import Icon from 'components/common/icons/Icons';
import Sidebar from 'components/sidebar/Sidebar';
import Modal from 'components/common/modal/Modal';
import Toast from 'components/common/toast/Toast';
import Loader from 'components/common/loader/Loader';

import { getObjectChanges } from 'utils/common/object';

import { convertToAddUserPayload } from 'utils/payload';
import { getUserById, updateUser } from 'services/users';

import UserForm from './UserForm';
import { updateUserSchema } from './schema';

function EditUser() {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState();
  const [userRole, setUserRole] = useState();
  const [toast, setToast] = useState({ hasError: false, message: '' });

  const [modal, setModal] = useState({
    visible: false,
    title: '',
    subtitle: '',
    changes: [],
    onConfirm: null,
  });

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const body = convertToAddUserPayload(data);

      await updateUser(userId, body);

      navigate(USER_MANAGEMENT);
    } catch (err) {
      setToast({
        hasError: true,
        message: err.response.data.detail || DEFAULT_ERROR_MESSAGE,
      });
    } finally {
      setIsSubmitting(false);
      handleModalClose();
    }
  };

  const handleUpdate = (data) => {
    const changes = getObjectChanges(formData, data);

    setModal({
      visible: true,
      title: 'Do you want to proceed with the changes?',
      subtitle: changes.length ? 'You have made the following changes:' : '',
      changes,
      onConfirm: () => handleSubmit(data),
    });
  };

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      getUserById(userId)
        .then((user) => {
          user.role && setUserRole(user.role);
          setFormData((prevState) => ({
            ...prevState,
            uname: user.uname,
            email: user.email,
            fname: user.fname,
            lname: user.lname,
            manager_id: user.manager && user.manager.user_id,
            role_id: user.role && user.role.urole_id,
            client_ids: user.clients
              ? user.clients.map((c) => c.client_id)
              : [],
          }));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userId]);

  const handleModalClose = () => {
    if (!isSubmitting) {
      setModal((prevState) => ({ ...prevState, visible: false }));
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main-content add-user-form px-5x pb-5x d-flex flex-direction-column">
        <div className="d-flex align-items-center mt-8x">
          <button
            className="btn user-form__back mr-8x"
            onClick={() => navigate(-1)}
          >
            <span>
              <Icon
                icon="arrowLeft"
                viewBox="0 0 16 16"
                size={12}
                color={colors.primary.base}
                className="mr-2x"
              />
            </span>
            Back
          </button>
          <h1 className="capitalize-first-letter">
            Edit {userRole && userRole.role_name}
          </h1>
        </div>
        {isLoading ? (
          <Loader isFullScreen />
        ) : (
          formData && (
            <UserForm
              initialData={formData}
              onSubmit={handleUpdate}
              validationSchema={updateUserSchema}
              isEdit
            />
          )
        )}
      </div>
      {toast.message && (
        <Toast
          title={toast.message}
          hasError={toast.hasError}
          handleClose={() => setToast({ message: '', hasError: false })}
        />
      )}
      {modal.visible && (
        <Modal>
          <div className="mb-3x">
            <h3 className="font-secondary">{modal.title}</h3>
            <p className="color-grey--80">{modal.subtitle}</p>
            <ul className="enable-style ml-6x">
              {modal.changes.map((c) => {
                const message = USER_FORM_FIELDS[c];

                return (
                  message && (
                    <li className="p-1x" key={c}>
                      <p className="color-grey--80">{message}</p>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn color-primary--base"
              onClick={handleModalClose}
            >
              Go Back
            </button>
            <button
              className={classNames('btn btn-primary', {
                'has-loader': isSubmitting,
              })}
              onClick={modal.onConfirm}
              disabled={isSubmitting}
            >
              Proceed
              {isSubmitting && <span className="spinner" />}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default EditUser;
