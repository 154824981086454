import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import { ValidationError } from 'yup';

import { getUsers } from 'services/users';
import { getAllRoles, getRolePermissions } from 'services/urole';

import Icon from 'components/common/icons/Icons';
import Input from 'components/common/Input/Input';
import Toast from 'components/common/toast/Toast';
import SelectBox from 'components/common/select/Select';

import { APP_ROLES } from 'constants/roles';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

import AssignClients from './AssignClients';

import {
  CAN_BE_ASSIGNED_CLIENT,
  CAN_BE_ASSIGNED_MANAGER,
} from 'constants/permissions';
import { USER_FORM_FIELDS } from 'constants/userManagement';
import { get } from 'utils/storage';
import { USER_ID } from 'constants/storage';
import { removeEmptyPayloadValues } from 'utils/payload';

const UserForm = ({ initialData, onSubmit, validationSchema, isEdit }) => {
  const { userId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isRetypePasswordVisible, setisRetypePasswordVisible] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(initialData);
  const [toast, setToast] = useState({ hasError: false, message: '' });
  const [permissions, setPermissions] = useState([]);

  const navigate = useNavigate();

  // Fetch and set required data
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [roles, users] = await Promise.all([
        getAllRoles(),
        getUsers({ roles: APP_ROLES.MANAGER }),
      ]);

      const formattedRoles = roles.map((r) => ({
        label: r.role_name,
        value: r.urole_id,
      }));

      const formattedUsers = users.map((u) => ({
        label: `${u.fname} ${u.lname}`,
        value: u.user_id,
      }));

      setRoleOptions(formattedRoles);
      setManagerOptions(formattedUsers);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setToast({
        hasError: true,
        message: error.response.detail.message || DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (formData.role_id) {
      setIsLoading(true);
      getRolePermissions(formData.role_id)
        .then((permissions) => {
          const permissionNames = permissions.map(
            (permission) => permission.name
          );
          setPermissions(permissionNames);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [formData.role_id]);

  // set form values on input field change
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // handle form validation for the specific field on blur
  const handleFormBlur = async (e) => {
    const { name, value } = e.target;
    try {
      await validationSchema.validateAt(name, { ...formData, [name]: value });
      setErrors({ ...errors, [name]: '' });
    } catch (err) {
      setErrors({ ...errors, [name]: err.message });
    }
  };

  // validate form call onSubmit
  const handleSubmit = async () => {
    try {
      // validate the form data according to the schema passed
      await validationSchema.validate(formData, { abortEarly: false });

      // reset errors to be empty after validation passes
      setErrors({});

      onSubmit(
        formData,
        roleOptions.find((r) => parseInt(r.value) === formData.role_id).label
      );
    } catch (err) {
      if (err instanceof ValidationError) {
        const validationErrors = {};

        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);

        return;
      }
    }
  };

  const onRoleChange = (data) => {
    const value = parseInt(data.value);
    setFormData({ ...formData, role_id: value });
    handleFormBlur({
      target: { value, name: 'role_id' },
    });
  };

  const onManagerChange = (data) => {
    const value = parseInt(data.value);
    setFormData({
      ...formData,
      manager_id: value,
    });
    handleFormBlur({
      target: { value, name: 'manager_id' },
    });
  };

  const changePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const changeRetypePasswordVisibility = () => {
    setisRetypePasswordVisible((prevState) => !prevState);
  };

  const icon = isPasswordVisible ? 'eye' : 'eyeClosed';
  const passwordType = isPasswordVisible ? 'text' : 'password';

  const retypePasswordIcon = isRetypePasswordVisible ? 'eye' : 'eyeClosed';
  const retypePasswordType = isRetypePasswordVisible ? 'text' : 'password';

  // checking for any changes in the form to disable/enable update button
  const isFormEqual =
    JSON.stringify(removeEmptyPayloadValues(initialData, true)) ===
    JSON.stringify(removeEmptyPayloadValues(formData, true));

  const canBeAssignedManager = permissions.includes(CAN_BE_ASSIGNED_MANAGER);
  const canBeAssignedClient = permissions.includes(CAN_BE_ASSIGNED_CLIENT);

  const isOwnEdit = isEdit && parseInt(userId) === get(USER_ID);

  return (
    <div className="bg-white--base">
      <div className="p-6x">
        <b className="font-secondary">User Details</b>
        <div className="user-form mb-8x mt-3x">
          <Input
            label={USER_FORM_FIELDS.fname}
            type="text"
            placeholder="Enter Firstname"
            name="fname"
            value={formData.fname}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            error={errors.fname}
            required
          />

          <Input
            placeholder="Enter Lastname"
            name="lname"
            value={formData.lname}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            error={errors.lname}
            label={USER_FORM_FIELDS.lname}
            required
          />

          <Input
            placeholder="Enter Username"
            name="uname"
            value={formData.uname}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            label={USER_FORM_FIELDS.uname}
            error={errors.uname}
            required
          />

          <Input
            type="email"
            label={USER_FORM_FIELDS.email}
            placeholder="Enter Email"
            name="email"
            value={formData.email}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            error={errors.email}
            required
          />
          <div className="form-group user-form__dropdown">
            <label className="form__label">Assign Role*</label>
            <SelectBox
              options={roleOptions}
              placeholder="Select Role"
              value={roleOptions.find(
                (role) => role.value === formData.role_id
              )}
              onChange={onRoleChange}
              isLoading={isLoading}
              isDisabled={isOwnEdit}
              filterOption={(option) =>
                // Hiding the admin options
                option.label.toLowerCase() !== APP_ROLES.ADMIN
              }
            />
            {errors.role_id && (
              <p className="user-form__error-msg text-sm mt-1x">
                {errors.role_id}
              </p>
            )}
          </div>
          <div className="form-group user-form__dropdown">
            <label className="form__label">Assign Manager*</label>
            <SelectBox
              options={managerOptions}
              placeholder="Select Manager"
              value={managerOptions.find(
                (role) => role.value === formData.manager_id
              )}
              onChange={onManagerChange}
              isLoading={isLoading}
              isDisabled={!canBeAssignedManager || isOwnEdit}
            />
            {errors.manager_id && (
              <p className="user-form__error-msg text-sm mt-1x">
                {errors.manager_id}
              </p>
            )}
          </div>
        </div>
        <b className="font-secondary">Create New Password</b>
        <div className="user-form mb-8x mt-3x">
          <Input
            label="New Password"
            type={passwordType}
            placeholder="Enter New Password"
            name="password"
            value={formData.password}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            error={errors.password}
            autoComplete="new-password"
            rightElement={
              <button
                type="button"
                onClick={changePasswordVisibility}
                tabIndex={-1} // Skip focus from the eye icon when tab is pressed after filling password
              >
                <Icon icon={icon} size={14} color="#57585A" />
              </button>
            }
            required
          />
          <Input
            placeholder="Re-enter New Password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            type={retypePasswordType}
            label="Re-enter New Password"
            error={errors.confirmPassword}
            autoComplete="new-password"
            rightElement={
              <button
                type="button"
                onClick={changeRetypePasswordVisibility}
                tabIndex={-1}
              >
                <Icon icon={retypePasswordIcon} size={14} color="#57585A" />
              </button>
            }
            required
          />
        </div>
      </div>
      <hr />
      {canBeAssignedClient && (
        <AssignClients setFormData={setFormData} isEdit={isEdit} />
      )}
      <hr />
      <div className="user-form__form-buttons p-5x">
        <button
          className="btn m-1x color-primary--base"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary m-1x"
          onClick={handleSubmit}
          disabled={isFormEqual}
        >
          {isEdit ? 'Update' : 'Add'}
        </button>
      </div>
      {toast.message && (
        <Toast
          title={toast.message}
          hasError={toast.hasError}
          handleClose={() => setToast({ message: '', hasError: false })}
        />
      )}
    </div>
  );
};

UserForm.defaultProps = {
  isEdit: false,
};

UserForm.propTypes = {
  initialData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.any.isRequired,
  isEdit: PropTypes.bool,
};

export default UserForm;
