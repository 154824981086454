import http from 'utils/http';
import { refresh, logOut } from 'services/auth';
import * as tokenService from 'services/token';
import * as storageService from 'utils/storage';

import { LOGIN } from 'constants/routes';

import { USERNAME, PERMISSIONS, ROLE, USER_ID } from 'constants/storage';
import parseJwt from 'utils/jwt';

const UNAUTHORIZED_STATUS_CODE = 401;
const BAD_REQUEST_STATUS_CODE = 400;

const UNAUTHORIZED_MESSAGE = 'Unauthorized';

const AUTHORIZATION_HEADER = 'Authorization';

const INVALID_REFRESH_TOKEN_MESSAGE =
  'Invalid refresh token. Please log in again.';

/**
 * Build authorization header
 *
 * @param {string} accessToken
 * @returns {string}
 */
function getAuthorizationHeader(accessToken) {
  return `Bearer ${accessToken}`;
}

/**
 * Interceptor to add Access Token header for all requests.
 *
 * @param {Request} request
 * @returns {Request}
 */
export function authorizationInterceptor(request) {
  const accessToken = tokenService.getAccessToken();

  if (accessToken && !request.headers[AUTHORIZATION_HEADER]) {
    request.headers[AUTHORIZATION_HEADER] = getAuthorizationHeader(accessToken);
  }

  return request;
}

/**
 * Interceptor to refresh Authorization header.
 *
 * @param {Object} error
 * @returns {Object}
 */
export async function unauthorizedResponseHandlerInterceptor(error) {
  if (!error.response) {
    return Promise.reject(error);
  }

  const originalRequest = error.config;

  const { message } = error.response.data;

  const statusCode = error.response.status;

  if (
    statusCode === UNAUTHORIZED_STATUS_CODE &&
    message === UNAUTHORIZED_MESSAGE &&
    !originalRequest.__isRetryRequest
  ) {
    originalRequest._retry = true;

    const refreshToken = tokenService.getRefreshToken();
    const { data } = await refresh(refreshToken);

    const access_token = data.access;
    tokenService.setAccessToken(access_token);
    const { role, permissions, user_id } = parseJwt(access_token);
    storageService.set(USERNAME, data.username);
    storageService.set(PERMISSIONS, permissions || []);
    storageService.set(ROLE, role || '');
    storageService.set(USER_ID, user_id);

    originalRequest.headers[AUTHORIZATION_HEADER] = getAuthorizationHeader(
      access_token
    );

    return http.request(originalRequest);
  }

  if (
    error.response.status === BAD_REQUEST_STATUS_CODE &&
    message === INVALID_REFRESH_TOKEN_MESSAGE
  ) {
    logOut();
    window.location.replace(LOGIN);
  }

  return Promise.reject(error);
}
