import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/common/icons/Icons';

const ContinueButton = (props) => {
  return (
    <>
      <button className="btn-continue w-100 px-7x" onClick={props.onClick}>
        <Icon
          className="mt-4x mr-5x"
          width={42}
          icon="longArrow"
          color="#055D54"
        />
        Continue to next step
      </button>
    </>
  );
};

ContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ContinueButton;
