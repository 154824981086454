import React from 'react';
import PropTypes, { object, string } from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Icon from 'components/common/icons/Icons';

import {
  CHECKBOX_COLUMN,
  EDIT_BUTTON_COLUMN,
  EXPLORE_BUTTON_COLUMN,
  VIEWDETAIL_BUTTON_COLUMN,
} from './constants';

export const CustomOption = (props) => {
  const { label, value, onChange, isSelected } = props;

  return (
    <li>
      <label className="custom-checkbox">
        <input
          type={'checkbox'}
          checked={isSelected}
          onChange={onChange}
          value={value}
        />
        <span className="box"></span>
        {label}
      </label>
    </li>
  );
};

CustomOption.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isSelected: PropTypes.bool,
};

class CustomColumnSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
    };
    this.wrapperRef = React.createRef(null);
  }

  getOptionsList = () => {
    const { selectedColumns, columnConfig } = this.props;

    return columnConfig
      .filter((col) => col.field !== CHECKBOX_COLUMN)
      .filter((col) => col.field !== EDIT_BUTTON_COLUMN)
      .filter((col) => col.field !== EXPLORE_BUTTON_COLUMN)
      .filter((col) => col.field !== VIEWDETAIL_BUTTON_COLUMN)
      .map((col) => {
        return {
          label: col.title,
          value: col.field,
          isSelected: selectedColumns.includes(col.field),
        };
      });
  };

  handleOptionClick = (event) => {
    this.props.handleSelect(event.target.value);
  };

  toggleShowOptions = () => {
    this.setState({
      showOptions: !this.state.showOptions,
    });
  };

  handleClickOutside = (event) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        showOptions: false,
      });
    }
  };

  detectOutsideClick = () => {
    document.addEventListener('mousedown', this.handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', this.handleClickOutside);
    };
  };

  render() {
    const optionsList = this.getOptionsList();
    this.detectOutsideClick(this.wrapperRef);

    const dropDownClassname = this.props.className
      ? `dropdown__list ${this.props.className}`
      : 'dropdown__list';

    return (
      <div className="dropdown no-arrow" ref={this.wrapperRef}>
        <button
          className="btn-ghost px-2x py-1x"
          onClick={this.toggleShowOptions}
        >
          <Icon
            icon="column"
            className="mr-2x icon-column"
            width={12}
            height={14}
            color="#DADADA"
          />
          Columns
        </button>
        {this.state.showOptions ? (
          <div className={dropDownClassname}>
            <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={300}>
              <ul>
                {optionsList.map((option, index) => {
                  return (
                    <CustomOption
                      key={index}
                      label={option.label}
                      value={option.value}
                      isSelected={option.isSelected}
                      onChange={this.handleOptionClick}
                    />
                  );
                })}
              </ul>
            </Scrollbars>
          </div>
        ) : null}
      </div>
    );
  }
}

CustomColumnSelector.propTypes = {
  className: PropTypes.string,
  handleSelect: PropTypes.func,
  columnConfig: PropTypes.arrayOf(object),
  selectedColumns: PropTypes.arrayOf(string),
};

export default CustomColumnSelector;
