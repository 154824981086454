import config from 'config';
import http from 'utils/http';
import { interpolate } from 'utils/common/string';
import { removeEmptyPayloadValues } from 'utils/payload';

export async function getAlgorithms(clientId) {
  const url = interpolate(config.endpoints.rulesV1, { clientId });
  const { data } = await http.get(url);

  return data.data;
}

export async function getAlgorithmsCategories() {
  const url = config.endpoints.ruleCategoriesV1;
  const { data } = await http.get(url);

  return data.data;
}

export async function updateRuleBaseById(ruleId, ruleDetails) {
  const url = interpolate(config.endpoints.updateRuleV1, {
    ruleId,
  });

  const payload = {
    client_id: ruleDetails.clientId,
    rule_name: ruleDetails.name,
    rule_category: ruleDetails.category,
    rule_state: ruleDetails.state,
    rule_definition: btoa(ruleDetails.ruleDefinition),
    priority: ruleDetails.priority,
    update_user: ruleDetails.updateUser,
  };

  const { data } = await http.put(url, removeEmptyPayloadValues(payload, true));

  return data.data;
}

export async function addNewRuleBase(ruleDetails) {
  const url = config.endpoints.createRuleV1;
  const { data } = await http.post(url, {
    client_id: ruleDetails.clientId,
    rule_name: ruleDetails.name,
    rule_category: ruleDetails.category,
    rule_state: ruleDetails.state,
    rule_definition: btoa(ruleDetails.ruleDefinition),
    priority: ruleDetails.priority,
    version: ruleDetails.version,
    update_user: ruleDetails.updateUser,
  });

  return data.data;
}
