import config from 'config';
import http from 'utils/http';
import { interpolate } from 'utils/common/string';

export async function getFiles(clientId) {
  const url = interpolate(config.endpoints.filesV1, { clientId });
  const { data } = await http.get(url);

  return data.data;
}

export async function getComputedStatistics(projectId, clientId) {
  const url = interpolate(config.endpoints.computedStatisticsV1, {
    projectId,
    clientId,
  });
  const { data } = await http.get(url);

  return data.data;
}

export async function getAggregateStatistics(projectId, clientId) {
  const url = interpolate(config.endpoints.aggregateStatisticsV1, {
    projectId,
    clientId,
  });
  const { data } = await http.get(url);

  return data.data;
}

export async function connectToProject(projectId, clientId, etlFileIDs) {
  const url = config.endpoints.connectFilesV1;
  const { data } = await http.put(url, {
    etl_fileids: etlFileIDs,
    project_id: projectId,
    client_id: clientId,
  });

  return data.data;
}

export async function computeStatistics(projectId, clientId) {
  const url = config.endpoints.computeStatsV1;
  const { data } = await http.put(url, {
    project_id: projectId,
    client_id: clientId,
  });

  return data.data;
}
