import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Sidebar from 'components/sidebar/Sidebar';
import Toast from 'components/common/toast/Toast';
import Loader from 'components/common/loader/Loader';
import Dropdown from 'components/common/dropdown/Dropdown';
import EmptyPage from 'components/common/emptypage/EmptyPage';
import InformationCard from 'components/common/information-card/InformationCard';

import RuleHitsTable from './RuleHitsTable';
import DataIntegrityTable from './DataIntegrityTable';
import AccountInsuranceTable from './AccountInsuranceTable';
import AccountFinancialsTable from './AccountFinancialsTable';
import AccountBillReferenceTable from './AccountBillReferenceTable';

import {
  getAccountInformation,
  getCrownComputedDates,
  getCrownComputedTotals,
  downloadReports,
  getCrownComputedStatistics,
} from 'services/accountInformation';

import { updateClientID, updateAccountNumber } from 'actions/clientAction';

import { validatePattern } from 'utils/common/string';
import { formatAnyValue, formatDate } from 'utils/common/formatter';
import {
  ACCOUNT_INFO_NO_CLIENT_SELECTED,
  ACCOUNT_INFO_NO_ACCOUNT_NUMBER,
  DEFAULT_ERROR_MESSAGE,
  INVALID_ACCOUNT_NUMBER,
} from 'constants/errorMessages';
import { ALNUM_HYPHEN_UNDERSCORE_PATTERN } from 'constants/patterns';

import * as fileNames from './configFiles/exportFileNames';

import * as informationConfig from './configFiles/informationCardConfig';

const mapStateToProps = (state) => {
  const { selectedClientId, clientList, accountNumber } = state;

  return { selectedClientId, clientList, accountNumber };
};

class AccountInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      isLoading: false,
      errorMessage: '',
      shouldFetchNewData: false,
      accountInformation: {
        account_id: '',
        account_number: '',
        admission_date: '',
        state: '',
        statute_days_state: '',
        current_financial_class_name: '',
        current_financial_class_code: '',
        agency_code: '',
        bill_date_most_recent: '',
        bill_date_original: '',
        create_date: '',
        discharge_date: '',
        expected_payment: '',
        last_payment_date: '',
        patient_class_code: '',
        patient_class_name: '',
        patient_type_code: '',
        patient_type_name: '',
        stat_last_payment_commercial_date: '',
        stat_last_payment_date: '',
        stat_last_payment_patient_date: '',
        stat_last_transaction_date: '',
        total_account_balance: '',
        total_adjustments: '',
        total_charges: '',
        total_insurance_payments: '',
        total_patient_payments: '',
        total_payments: '',
        calc_total_account_balance: '',
        calc_group_charges_count: '',
        calc_group_balance_count: '',
      },
    };
  }

  async componentDidMount() {
    const { selectedClientId, accountNumber } = this.props;

    if (selectedClientId && accountNumber) {
      await this.updateAccountInformation();
    }
  }

  handleAccountNumberFieldBlur = (event) => {
    const accountNumber = event.target.value;

    this.props.updateAccountNumber(accountNumber);
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.props.updateClientID(clientId);
  };

  handleError = (errorMessage) => {
    if (this.state.hasError) {
      return;
    }

    this.setState({
      hasError: true,
      errorMessage: errorMessage,
    });
  };

  handleCloseToast = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  updateAccountInformation = async () => {
    const { selectedClientId, accountNumber } = this.props;

    if (!selectedClientId) {
      this.handleError(ACCOUNT_INFO_NO_CLIENT_SELECTED);

      return;
    }

    if (!accountNumber) {
      this.handleError(ACCOUNT_INFO_NO_ACCOUNT_NUMBER);

      return;
    }

    if (!validatePattern(accountNumber, ALNUM_HYPHEN_UNDERSCORE_PATTERN)) {
      this.handleError(INVALID_ACCOUNT_NUMBER);

      return;
    }

    this.setState({
      isLoading: true,
      hasError: false,
      shouldFetchNewData: true,
      errorMessage: '',
    });

    try {
      const accountInformation = await getAccountInformation(
        accountNumber,
        selectedClientId
      );
      if (accountInformation) {
        this.setState({
          accountInformation: accountInformation,
        });
      }

      const crownComputedDates = await getCrownComputedDates(
        accountNumber,
        selectedClientId
      );
      if (crownComputedDates) {
        this.setState({
          accountInformation: {
            ...this.state.accountInformation,
            ...crownComputedDates,
          },
        });
      }

      const crownComputedTotals = await getCrownComputedTotals(
        accountNumber,
        selectedClientId
      );
      if (crownComputedTotals) {
        this.setState({
          accountInformation: {
            ...this.state.accountInformation,
            ...crownComputedTotals,
          },
        });
      }

      const crownComputedStatistics = await getCrownComputedStatistics(
        accountNumber,
        selectedClientId
      );

      if (crownComputedStatistics) {
        this.setState({
          accountInformation: {
            ...this.state.accountInformation,
            ...crownComputedStatistics,
          },
        });
      }

      this.setState({
        isLoading: false,
        shouldFetchNewData: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        isLoading: false,
        shouldFetchNewData: false,
      });

      this.resetAccountInformation();

      this.handleError(errorMessage);
    }
  };

  extractInformation = (informationConfig) => {
    const { accountInformation } = this.state;

    const calcTotalAccountBalance =
      accountInformation.calc_total_account_balance;

    if (calcTotalAccountBalance) {
      accountInformation.calc_total_account_balance = Math.round(
        calcTotalAccountBalance
      ).toString();
    }

    return informationConfig.map((config) => {
      const { label, source } = config;
      let value = accountInformation[source] || '';

      if (config.format) {
        value = formatAnyValue(config.format, value);
      }

      if (config.joins) {
        const joinedValues = config.joins.map(
          (joinKey) => accountInformation[joinKey]
        );

        return {
          label,
          value: joinedValues.join(' | '),
        };
      }

      return {
        label,
        value,
      };
    });
  };

  handleCopy = async () => {
    const { selectedClientId, accountNumber } = this.props;
    const currentUrl = window.location.href;
    try {
      await navigator.clipboard.writeText(
        `${currentUrl}/${selectedClientId}/${accountNumber}`
      );
    } catch (error) {
      this.setState({
        hasError: true,
        errorMessage: DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  resetAccountInformation = () => {
    this.setState({
      accountInformation: {
        account_id: '',
        account_number: '',
        admission_date: '',
        current_financial_class_name: '',
        current_financial_class_code: '',
        agency_code: '',
        bill_date_most_recent: '',
        bill_date_original: '',
        create_date: '',
        discharge_date: '',
        expected_payment: '',
        last_payment_date: '',
        patient_class_code: '',
        patient_class_name: '',
        patient_type_code: '',
        patient_type_name: '',
        stat_last_payment_commercial_date: '',
        stat_last_payment_date: '',
        stat_last_payment_patient_date: '',
        stat_last_transaction_date: '',
        total_account_balance: '',
        total_adjustments: '',
        total_charges: '',
        total_insurance_payments: '',
        total_patient_payments: '',
        total_payments: '',
        calc_total_account_balance: '',
        calc_group_charges_count: '',
        calc_group_balance_count: '',
      },
    });
  };

  handleExport = async (tableName) => {
    const { accountNumber, selectedClientId, clientList } = this.props;
    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );

    try {
      const apiRoute = tableName.toLowerCase().split('_').join('-');
      const now = Date.now();
      const blob = await downloadReports(
        accountNumber,
        apiRoute,
        selectedClientId
      );
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        `${tableName}_${selectedClient.display_name}_${formatDate(now)}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.handleError(errorMessage);
    }
  };

  render() {
    const { clientList, accountNumber, selectedClientId } = this.props;
    const { hasError, isLoading, errorMessage, shouldFetchNewData } =
      this.state;

    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );
    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const clientName = selectedClient ? selectedClient.display_name : '';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });

    const clientInformation =
      selectedClientId || accountNumber
        ? `(${clientName} - ${accountNumber})`
        : '';

    return (
      <>
        <main>
          <div className="d-flex">
            <Sidebar />

            <div className="main-content px-5x pb-5x d-flex flex-direction-column">
              <div className="bg-grey--5 pt-5x sticky pb-3x d-flex align-items-center flex-direction-row">
                <div className="account__number__box ml-auto flex-horizontal">
                  <div className="account__number  mr-4x">
                    <input
                      className="account__number__input form__control"
                      type="text"
                      size={14}
                      placeholder={'Enter Account ID'}
                      defaultValue={accountNumber}
                      onBlur={this.handleAccountNumberFieldBlur}
                    />
                  </div>
                  <button
                    className="btn btn-primary has-loader"
                    onClick={this.updateAccountInformation}
                  >
                    Run {isLoading && <span className="spinner" />}
                  </button>
                </div>
                <div className="profile">
                  <Dropdown
                    label={displayName}
                    dropdownItems={dropdownItems}
                    onClick={this.handleSelectClient}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-end mb-3x">
                <h1>{`Account Information`}</h1>

                <button
                  className="btn btn-primary has-loader"
                  onClick={this.handleCopy}
                >
                  Copy
                </button>
              </div>
              {!selectedClientId || !accountNumber ? (
                <EmptyPage
                  selectionParameters={'client and account id'}
                  pageName={'account'}
                />
              ) : (
                <>
                  <div className="d-flex gap-6x mb-6x">
                    <div className="left-side flex-grow-1">
                      <div className="bg-primary--10 py-5x px-4x border-radius-4 mb-6x has-box-shadow">
                        <h4 className="mb-4x color-primary--base">
                          {`Account Details ${clientInformation}`}
                        </h4>

                        <div className="d-flex justify-content-between">
                          <div className="column">
                            {
                              <InformationCard
                                labelWidth={86}
                                informationList={this.extractInformation(
                                  informationConfig.accountInfo
                                )}
                                title={'Account Information'}
                              />
                            }
                          </div>
                          <div className="column">
                            {
                              <InformationCard
                                labelWidth={105}
                                informationList={this.extractInformation(
                                  informationConfig.accountDates
                                )}
                                title={'Account Dates'}
                              />
                            }
                          </div>
                          <div className="column value-right">
                            {
                              <InformationCard
                                labelWidth={121}
                                informationList={this.extractInformation(
                                  informationConfig.accountTotals
                                )}
                                title={'Account Totals'}
                              />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 h-100">
                          <DataIntegrityTable
                            shouldFetchNewData={shouldFetchNewData}
                          />
                        </div>
                        <div className="col-6 h-100">
                          <RuleHitsTable
                            shouldFetchNewData={shouldFetchNewData}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="right-side account__sidebar">
                      <div className="bg-green-glow--60 py-5x px-4x border-radius-4 h-100">
                        <h4 className="mb-4x color-ever-green--base">
                          Crown Computed Information
                        </h4>

                        <div className="value-right">
                          <InformationCard
                            labelWidth={145}
                            informationList={this.extractInformation(
                              informationConfig.crownComputedDates
                            )}
                            title={'Computed Dates'}
                          />
                        </div>

                        <div className="mt-4x value-right">
                          <InformationCard
                            labelWidth={155}
                            informationList={this.extractInformation(
                              informationConfig.crownComputedTotals
                            )}
                            title={'Computed Totals'}
                          />
                        </div>

                        <div className="mt-4x value-right">
                          <InformationCard
                            labelWidth={155}
                            informationList={this.extractInformation(
                              informationConfig.crownComputedStatistics
                            )}
                            title={'Computed Statistics'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <AccountFinancialsTable
                      handleError={this.handleError}
                      shouldFetchNewData={shouldFetchNewData}
                      exportTable={() =>
                        this.handleExport(fileNames.ACCOUNT_FINANCIALS)
                      }
                    />
                  </div>
                  <div>
                    <AccountBillReferenceTable
                      shouldFetchNewData={shouldFetchNewData}
                      exportTable={() =>
                        this.handleExport(fileNames.BILL_REFERENCE)
                      }
                    />
                  </div>
                  <div>
                    <AccountInsuranceTable
                      shouldFetchNewData={shouldFetchNewData}
                      exportTable={() =>
                        this.handleExport(fileNames.ACCOUNT_INSURANCE)
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {hasError && (
            <Toast
              title={errorMessage}
              hasError={hasError}
              handleClose={this.handleCloseToast}
            />
          )}
          {isLoading && <Loader isFullScreen={true} />}
        </main>
      </>
    );
  }
}

AccountInformation.propTypes = {
  updateClientID: PropTypes.func,
  updateAccountNumber: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  accountNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateAccountNumber,
})(AccountInformation);
