import Router from './Router';
import React, { Fragment } from 'react';

import 'assets/sass/style.scss';

const App = () => {
  return (
    <Fragment>
      <Router />
    </Fragment>
  );
};

export default App;
