import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Tag from 'components/common/tag/Tag';
import Icon from 'components/common/icons/Icons';
import Sidebar from 'components/sidebar/Sidebar';
import Table from 'components/common/table/Table';
import Toast from 'components/common/toast/Toast';
import Loader from 'components/common/loader/Loader';
import Dropdown from 'components/common/dropdown/Dropdown';
import EmptyPage from 'components/common/emptypage/EmptyPage';
import SwitchButton from 'components/common/switch/SwitchButton';
import CustomColumnSelector from 'components/common/table/CustomColumnSelector';

import { updateClientID, updateClientList } from 'actions/clientAction';

import { getAllClients } from 'services/client';
import {
  updateInsuranceData,
  getInsuranceByClientId,
  getInsuranceCategories,
  downloadReports,
} from 'services/insurance';

import columnConfig from './columnConfig';
import { tableConfig } from './tableConfig';
import { UNKNOWN_CATEGORY_ID } from 'constants/insurance';
import { HIGHLIGHT_ROW_CLASSNAME } from 'components/common/table/constants';

import { formatDate } from 'utils/common/formatter';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

const mapStateToProps = (state) => {
  const { selectedClientId, clientList } = state;

  return { selectedClientId, clientList };
};

class InsuranceMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      onlyFxCount: 0,
      hasError: false,
      updatedData: [],
      categoryList: [],
      errorMessage: '',
      isLoading: false,
      isEditing: false,
      hasNewData: false,
      showOnlyFx: false,
      filteredFields: [],
      selectedColumns: [],
      fetchedCategories: [],
      columnConfig: columnConfig,
      showUnknownCategories: false,
      onlyUnknownCategoriesCount: 0,
      updatedColumnConfig: {
        field: '',
        isShownByDefault: false,
      },
    };

    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({
      selectedColumns: columnConfig
        .filter((col) => col.isShownByDefault)
        .map((col) => col.field),
    });

    await this.updateCategoryList();
    await this.updateInsuranceData();

    if (this.props.clientList.length === 0) {
      await this.updateClientList();
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedClientId !== this.props.selectedClientId) {
      await this.updateInsuranceData();
    }
  }

  updateClientList = async () => {
    const data = await getAllClients();

    if (data) {
      this.props.updateClientList(data);
    }
  };

  updateInsuranceData = async () => {
    const { selectedClientId } = this.props;

    if (!selectedClientId) {
      return;
    }

    this.setState({
      isLoading: true,
      data: [],
      hasError: false,
      errorMessage: '',
    });

    try {
      const data = await getInsuranceByClientId(selectedClientId);

      this.setState({
        isLoading: false,
        data,
        hasError: false,
        errorMessage: '',
      });
    } catch (error) {
      const errorMessage = error.response.data || DEFAULT_ERROR_MESSAGE;

      this.setState({
        isLoading: false,
        data: [],
        hasError: true,
        errorMessage: errorMessage,
      });
    }
  };

  updateCategoryList = async () => {
    this.setState({
      isLoading: true,
    });

    try {
      const data = await getInsuranceCategories();

      this.setState({
        fetchedCategories: data,
        categoryList: data.map((category) => category.category_name),
        isLoading: false,
      });
    } catch (error) {
      const errorMessage = error.response.data || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  handleFilterFxToggle = () => {
    this.setState({
      showOnlyFx: !this.state.showOnlyFx,
    });
  };

  handleFilterUnknownCategories = () => {
    this.setState({
      showUnknownCategories: !this.state.showUnknownCategories,
    });
  };

  handleCategorySelect = (selectedColumnField) => {
    const { selectedColumns } = this.state;
    const selected = selectedColumns.includes(selectedColumnField);

    if (selected) {
      this.setState({
        selectedColumns: selectedColumns.filter(
          (col) => col !== selectedColumnField
        ),
      });
    }

    if (!selected) {
      this.setState({
        selectedColumns: [...selectedColumns, selectedColumnField],
      });
    }

    this.setState({
      updatedColumnConfig: {
        field: selectedColumnField,
        isShownByDefault: !selected,
      },
    });
  };

  getFilteredTableData = () => {
    const { showOnlyFx, showUnknownCategories, data } = this.state;

    if (showOnlyFx && !showUnknownCategories) {
      return data.filter((row) => row.is_financial_insurance);
    }

    if (!showOnlyFx && showUnknownCategories) {
      return data.filter(
        (row) => row.ch_insurance_category_id === UNKNOWN_CATEGORY_ID
      );
    }

    if (showOnlyFx && showUnknownCategories) {
      return data.filter(
        (row) =>
          row.is_financial_insurance &&
          row.ch_insurance_category_id === UNKNOWN_CATEGORY_ID
      );
    }

    return data;
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;
    this.setState({
      isEditing: false,
    });

    this.props.updateClientID(clientId);
  };

  handleCellEdit = (cell) => {
    const rowData = cell.getRow().getData();
    const { insurance_id, category_name } = rowData;
    const { data, fetchedCategories, updatedData } = this.state;

    const selectedInsuranceCategoryId = fetchedCategories.find(
      (category) => category.category_name === category_name
    ).ch_insurance_category_id;

    const rowIndex = data.findIndex((row) => row.insurance_id === insurance_id);
    if (rowIndex !== -1) {
      let newDataSet = [...data];
      newDataSet[rowIndex] = rowData;

      this.setState({
        data: newDataSet,
      });

      const editedRow = this.tableRef.current.tabulator.getRow(insurance_id);
      const rowElement = editedRow.getElement();
      rowElement.className = `${rowElement.className} ${HIGHLIGHT_ROW_CLASSNAME}`;
    }

    this.setState({
      isEditing: true,
      updatedData: [
        ...updatedData,
        {
          insurance_id,
          ch_insurance_category_id: selectedInsuranceCategoryId,
        },
      ],
    });
  };

  handleSave = async () => {
    const { updatedData, onlyUnknownCategoriesCount, hasNewData } = this.state;
    let updatedCount = 0;

    this.setState({
      isLoading: true,
    });

    try {
      await updateInsuranceData(this.props.selectedClientId, updatedData);

      updatedData.forEach((data) => {
        const editedRow = this.tableRef.current.tabulator.getRow(
          data.insurance_id
        );
        const rowElement = editedRow.getElement();
        const resetClassName = rowElement.className.replace(
          ` ${HIGHLIGHT_ROW_CLASSNAME}`,
          ''
        );
        rowElement.className = resetClassName;

        const existingData = this.state.data.find(
          (row) => row.insurance_id === data.insurance_id
        );
        const { ch_insurance_category_id } = existingData;

        if (
          ch_insurance_category_id !== UNKNOWN_CATEGORY_ID &&
          data.ch_insurance_category_id === UNKNOWN_CATEGORY_ID
        ) {
          updatedCount += 1;
        }

        if (
          ch_insurance_category_id === UNKNOWN_CATEGORY_ID &&
          data.ch_insurance_category_id !== UNKNOWN_CATEGORY_ID
        ) {
          updatedCount -= 1;
        }
      });

      this.setState({
        isLoading: false,
        errorMessage: '',
        isEditing: false,
        hasNewData: hasNewData,
        onlyUnknownCategoriesCount: onlyUnknownCategoriesCount + updatedCount,
        updatedData: [],
      });
    } catch (error) {
      this.setState({
        hasError: true,
        isLoading: false,
        errorMessage: 'Error when saving data',
      });
    }
  };

  handleReset = async () => {
    this.setState({
      isEditing: false,
      isLoading: true,
      updatedData: [],
      data: [],
    });

    try {
      const data = await getInsuranceByClientId(this.props.selectedClientId);

      this.setState({
        isLoading: false,
        isEditing: false,
        updatedData: [],
        data,
      });
    } catch (error) {
      this.setState({
        hasError: true,
        isLoading: false,
        isEditing: false,
        errorMessage: 'Error when getting data',
      });
    }
  };

  handleDataFiltered = (filters, rows) => {
    const filteredFields = filters.map((filter) => {
      const foundConfig = columnConfig.find(
        (config) => config.field === filter.field
      );

      if (!foundConfig && foundConfig.title) {
        return '';
      }

      return foundConfig.title;
    });

    const onlyFxCount = rows.filter(
      (row) => row.getData().is_financial_insurance
    ).length;
    const onlyUnknownCategoriesCount = rows.filter(
      (row) => row.getData().ch_insurance_category_id === UNKNOWN_CATEGORY_ID
    ).length;

    this.setState({
      onlyFxCount,
      filteredFields,
      onlyUnknownCategoriesCount,
    });
  };

  handleResetFilters = () => {
    const { tabulator } = this.tableRef.current;

    tabulator.clearHeaderFilter();
  };

  handleExport = async () => {
    const { selectedClientId, clientList } = this.props;
    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );

    try {
      const now = Date.now();
      const blob = await downloadReports(selectedClientId);
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        `Insurance_${selectedClient.display_name}_${formatDate(now)}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
      });
    }
  };

  render() {
    const {
      hasError,
      isLoading,
      isEditing,
      hasNewData,
      showOnlyFx,
      onlyFxCount,
      errorMessage,
      categoryList,
      columnConfig,
      filteredFields,
      selectedColumns,
      updatedColumnConfig,
      showUnknownCategories,
      onlyUnknownCategoriesCount,
    } = this.state;
    const { clientList, selectedClientId } = this.props;

    const tableData = this.getFilteredTableData();

    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });

    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );
    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the client';

    const componentClassName = classNames({
      'd-flex': true,
      'empty-page': !selectedClientId,
    });

    return (
      <main>
        <div className={componentClassName}>
          <Sidebar />
          <div className="main-content px-5x pb-5x d-flex flex-direction-column">
            <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
              <div className="profile mb-3x ml-auto">
                <Dropdown
                  label={displayName}
                  dropdownItems={dropdownItems}
                  onClick={this.handleSelectClient}
                />
              </div>

              <div className="d-flex justify-content-between align-items-end mb-3x">
                <h1>Insurance Mapping</h1>
                <div className="d-flex pt-2x gap-3x">
                  {selectedClientId ? (
                    <>
                      <CustomColumnSelector
                        columnConfig={columnConfig}
                        handleSelect={this.handleCategorySelect}
                        selectedColumns={selectedColumns}
                      />
                      <button
                        className="btn-ghost px-2x py-1x"
                        onClick={this.handleExport}
                      >
                        <Icon
                          icon="export"
                          className="mr-2x icon-export"
                          width={10}
                          height={14}
                          color="#DADADA"
                        />
                        Export
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
              {selectedClientId ? (
                <div className="d-flex justify-content-between align-items-center bg-white--base py-2x px-4x mb-1x border-radius-4">
                  <div className="switcher-filters">
                    <SwitchButton
                      key={'OnlyFx'}
                      labelText={'Only FX'}
                      value={showOnlyFx}
                      badgeCount={onlyFxCount}
                      onClick={this.handleFilterFxToggle}
                    />
                    <SwitchButton
                      key={'OnlyUnknownCategory'}
                      labelText={'Only Unknown Category'}
                      value={showUnknownCategories}
                      badgeCount={onlyUnknownCategoriesCount}
                      onClick={this.handleFilterUnknownCategories}
                    />
                  </div>
                  <Tag
                    maxWidthInPx={800}
                    tags={filteredFields}
                    resetTags={this.handleResetFilters}
                  />
                </div>
              ) : null}
            </div>
            {selectedClientId ? (
              <>
                <div className="table">
                  {categoryList.length > 0 && (
                    <Table
                      ref={this.tableRef}
                      data={tableData}
                      hasNewData={hasNewData}
                      cellEdited={this.handleCellEdit}
                      columnConfig={columnConfig}
                      categoryList={categoryList}
                      tableConfig={tableConfig}
                      selectedColumns={selectedColumns}
                      onDataFiltered={this.handleDataFiltered}
                      updatedColumnConfig={updatedColumnConfig}
                    />
                  )}
                </div>
                {isLoading && <Loader isFullScreen={true} />}
              </>
            ) : (
              <EmptyPage
                selectionParameters={'client'}
                pageName={'insurance mapping'}
              />
            )}
          </div>
        </div>
        {isEditing && (
          <Toast
            title="Careful &mdash; You've unsaved changes"
            hasActions={true}
            handleSave={this.handleSave}
            handleReset={this.handleReset}
            hasError={false}
          />
        )}
        {hasError && (
          <Toast
            title={errorMessage}
            hasError={hasError}
            handleClose={this.resetError}
          />
        )}
      </main>
    );
  }
}

InsuranceMapping.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  selectedClientId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  clientList: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps, { updateClientID, updateClientList })(
  InsuranceMapping
);
