const config = {
  env: process.env.NODE_ENV || 'development',
  baseURL: process.env.REACT_APP_BASE_URL,
  idleLogoutTime: 30 * 60 * 1000,
  endpoints: {
    login: '/v1/auth/login',
    clientList: '/v1/clients',
    refresh: '/v0/refresh/token',

    // users
    users: '/v1/users',
    usersWithClients: 'v1/users/clients',
    updatePassword: '/v1/users/:userId/password',
    userDetail: '/v1/users/:userId',
    userMFAQR: '/v1/users/:userId/mfa',

    // insurance
    insuranceCategories: '/v0/insuranceCategories',
    insuranceMapping: '/v0/mappingInsuranceByClientId/:clientId',
    exportInsurance: 'v1/mapping/insurance-mapping/export?client_id=:clientId',

    // transaction
    financialCategories: '/v0/financialCategories',
    transactionMapping: '/v0/transactionMappingByClientId/:clientId',
    exportTransaction:
      '/v1/mapping/transaction-mapping/export?client_id=:clientId',

    // account information
    accountInformation:
      '/v0/accountDetails/clientId/:clientId/acctNumber/:accountNumber',
    crownComputedInformation:
      '/v0/crownComputed/clientId/:clientId/acctNumber/:accountNumber',
    accountFinancials:
      '/v0/financialDetailByClientId/:clientId/accountNumber/:accountNumber',
    accountBillReference:
      '/v0/billReferenceDetailByClientId/:clientId/accountNumber/:accountNumber',
    accountInsurance:
      '/v0/insuranceDetailByClientId/:clientId/accountNumber/:accountNumber',
    dataIntegrity:
      '/v0/dataIntegrity/byClientId/:clientId/projectId/:accountNumber',
    ruleHits: '/v0/ruleHits/byClientId/:clientId/projectId/:accountNumber',

    // account information v1 endpoints
    accountInformationV1: '/v1/accounts/:accountId?client_id=:clientId',
    crownComputedDatesV1:
      '/v1/accounts/:accountId/computed-dates?client_id=:clientId',
    crownComputedTotalsV1:
      '/v1/accounts/:accountId/computed-totals?client_id=:clientId',
    dataIntegrityV1:
      '/v1/accounts/:accountId/data-integrity?client_id=:clientId',
    ruleHitsV1: '/v1/accounts/:accountId/rule-hits?client_id=:clientId',
    accountFinancialsV1:
      '/v1/accounts/:accountId/account-financials?client_id=:clientId',
    accountBillReferenceV1:
      '/v1/accounts/:accountId/bill-reference?client_id=:clientId',
    accountInsuranceV1:
      '/v1/accounts/:accountId/account-insurance?client_id=:clientId',
    exportAccountData:
      '/v1/accounts/:accountId/:tableName/export?client_id=:clientId',
    crownComputedStatistics:
      '/v1/accounts/:accountId/computed-statistics?client_id=:clientId',

    // rules Library v1 endpoints
    rulesV1: '/v1/rules?client_id=:clientId',
    updateRuleV1: '/v1/rules/:ruleId',
    createRuleV1: '/v1/rules',
    ruleCategoriesV1: '/v1/rule-categories',

    // project Analysis v1 endpoints
    projectsV1: '/v1/projects?client_id=:clientId',
    createProjectV1: '/v1/projects',
    updateProjectV1: '/v1/projects/:projectId',
    exportProjectV1: '/v1/projects/:projectId/reports',

    // data chooser v1 endpints
    filesV1: '/v1/data-chooser?client_id=:clientId',
    connectFilesV1: '/v1/data-chooser/connect',
    computeStatsV1: '/v1/data-chooser/compute-stats',
    aggregateStatisticsV1:
      '/v1/data-chooser/:projectId/aggregate-statistics?client_id=:clientId',
    computedStatisticsV1:
      '/v1/data-chooser/:projectId/computed-statistics?client_id=:clientId',

    // mapping endpoints
    insuranceMappingDataV1: '/v1/mapping/insurance-mapping?client_id=:clientId',
    transactionMappingDataV1:
      '/v1/mapping/transaction-mapping?client_id=:clientId',

    // validation checker endpoints
    runValidations: '/v1/validation-check',
    validationChecker: '/v1/validation-check/:projectId',
    validationCheckerStatus: '/v1/validation-check/status/:projectId',

    // rule library endpoints
    getAssociatedRules: '/v1/rule-library/:projectId?client_id=:clientId',
    connectRules: '/v1/rule-library/:projectId',

    // qa page endpoints
    getQAData: '/v1/qa/:projectId/results',
    trashAndRunAll: '/v1/qa/run-all',
    runSingleRule: '/v1/qa/run-rule',
    deleteSingleRule: '/v1/qa/rule/:projectRuleBaseId',
    getQARuleDetails: '/v1/qa/:projectRuleBaseId/details',
    exportQARuleDetails: '/v1/qa/:projectRuleBaseId/details/export',

    // statement page endpoints
    statementCategories: '/v1/statement/statuses',
    getProjectStatement: '/v1/statement/:projectId',
    updateProjectStatement: '/v1/statement/:projectId',

    // job related endpoints
    jobData: '/v1/jobs',
    jobStatus: '/v1/jobs/:projectId',

    // Publisher endpoints
    getPublisherData: '/v1/publisher/:projectId/rules',
    exportReport: '/v1/publisher/:projectId/export',

    // clients v1 endpoints
    clients: '/v1/clients',

    // uroles
    uroles: '/v1/uroles',
    urolePermissions: '/v1/uroles/:uroleId/permissions',

    // MFA
    rememberDevice: '/v1/mfa/remember-device',
  },
};

export default config;
