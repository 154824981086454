import config from 'config';
import http from 'utils/http';
import { interpolate } from 'utils/common/string';

export async function getValidationCheckerData(projectId) {
  const url = interpolate(config.endpoints.validationChecker, { projectId });
  const { data } = await http.get(url);

  return data.data;
}

export async function runValidations(projectId, clientId) {
  const url = config.endpoints.runValidations;
  const { data } = await http.put(url, {
    project_id: projectId,
    client_id: clientId,
  });

  return data.data;
}

export async function checkValidationStatus(projectId) {
  const url = interpolate(config.endpoints.validationCheckerStatus, {
    projectId,
  });
  const { data } = await http.get(url);

  return data.data;
}

export async function deleteValidations(projectId, clientId) {
  const url = interpolate(config.endpoints.validationChecker, { projectId });
  const { data } = await http.delete(url, {
    project_id: projectId,
    client_id: clientId,
  });

  return data.data;
}
