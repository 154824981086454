import { NUMBER_TYPE } from 'constants/formatConfig';

/**
 * Build supplied string by interpolating properties inside delimiters('{ }') with the given parameters.
 *
 * @example
 * interpolate(':name is here.', {name: 'Barbara'})
 * => 'Barbaba is here.'
 *
 * @param {string} str
 * @param {object} params
 * @returns {string}
 */
export function interpolate(str, params) {
  let formattedString = str;

  for (const [key, value] of Object.entries(params)) {
    const val = value || '';

    formattedString = formattedString.replace(
      new RegExp(':' + key, 'gi'),
      val.toString()
    );
  }

  return formattedString;
}

export function sanitize(string) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;',
  };
  const reg = /[&<>"'/]/gi;

  return string.replace(reg, (match) => map[match]);
}

export function convertCurrencyToNumber(currency) {
  if (currency === null || currency === undefined) {
    return null;
  }

  if (typeof currency === NUMBER_TYPE) {
    return currency;
  }

  let sanitizedCurrency = currency.replaceAll('$', '').replaceAll(',', '');
  let parsedCurrency = parseFloat(sanitizedCurrency);

  if (isNaN(parsedCurrency)) {
    return null;
  }

  return parsedCurrency;
}

export function removeCommasFromNumber(value, returnZero = true) {
  if (value === undefined || value === null) {
    return null;
  }

  if (value === '' && returnZero) {
    return 0;
  }

  if (typeof value === NUMBER_TYPE) {
    return value;
  }

  let sanitizedValue = value.replaceAll(',', '');
  let parsedValue = parseFloat(sanitizedValue);

  if (isNaN(parsedValue)) {
    return null;
  }

  return parsedValue;
}

/**
 * Validates if a given string matches a specified pattern.
 *
 * @param {string} string - The string to be validated.
 * @param {string} pattern - The regular expression pattern to validate against.
 * @returns {boolean} - Returns true if the string matches the pattern, false otherwise.
 */
export function validatePattern(string, pattern) {
  const regex = new RegExp(pattern);

  return regex.test(string);
}
