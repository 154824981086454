import * as clientActions from 'actions/clientAction';

const INITIAL_STATE = {
  clientList: [],
  selectedClientId: '',
  accountNumber: '',
  selectedProject: {
    projectName: '',
    projectId: '',
    status: '',
    lastUpdateDate: '',
  },
  selectedProjectRuleBaseId: '',
};

export default function (appState = INITIAL_STATE, action) {
  const { payload } = action;

  switch (action.type) {
    case clientActions.UPDATE_CLIENT_ID:
      return {
        ...appState,
        selectedClientId: payload,
      };

    case clientActions.UPDATE_CLIENT_LIST:
      return {
        ...appState,
        clientList: payload,
      };

    case clientActions.UPDATE_ACCOUNT_NUMBER:
      return {
        ...appState,
        accountNumber: payload,
      };

    case clientActions.UPDATE_SELECTED_PROJECT:
      return {
        ...appState,
        selectedProject: {
          projectName: payload.projectName,
          status: payload.status,
          projectId: payload.projectId,
          lastUpdateDate: payload.lastUpdateDate,
        },
      };

    case clientActions.CLEAR_SELECTED_PROJECT:
      return {
        ...appState,
        selectedProject: {
          projectName: '',
          projectId: '',
          lastUpdateDate: '',
        },
      };

    case clientActions.UPDATE_SELECTED_PROJECT_BASE_ID:
      return {
        ...appState,
        selectedProjectRuleBaseId: payload,
      };

    case clientActions.LOGOUT:
      return {
        ...appState,
        ...INITIAL_STATE,
      };

    default:
      return appState;
  }
}
