import React from 'react';
import PropTypes from 'prop-types';

const SwitchButton = (props) => {
  const { value, labelText, onClick, badgeCount } = props;

  const defaultClassName = 'btn-ghost text-sm switcher__label';

  let updatedClassName = value
    ? `${defaultClassName} selected`
    : defaultClassName;

  return (
    <>
      <label className={updatedClassName}>
        <input
          className="switcher__checkbox"
          type="checkbox"
          onChange={onClick}
          checked={value}
        />
        <div className="switch">
          <div className="dot"></div>
        </div>
        <span className="switcher__text">{labelText}</span>
        <span className="badge">{badgeCount}</span>
      </label>
    </>
  );
};

SwitchButton.propTypes = {
  value: PropTypes.bool,
  labelText: PropTypes.string,
  onClick: PropTypes.func,
  badgeCount: PropTypes.number,
};

export default SwitchButton;
