import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, Navigate } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';

import logo from 'assets/images/brand-logo.svg';
import logoMini from 'assets/images/brand-logo-mini.svg';

import Icon from 'components/common/icons/Icons';

import { LOGOUT } from 'actions/clientAction';

import * as routes from 'constants/routes';

import * as storage from 'utils/storage';

const toggleHandler = () => {
  const toggler = document.querySelector('.aside__toggler');
  const nav = document.querySelector('.aside');

  toggler.classList.toggle('active');
  nav.classList.toggle('shrink');
};

const Sidebar = () => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    storage.clear();
    dispatch({
      type: LOGOUT,
    });

    setIsLoggedOut(true);
  };

  return isLoggedOut ? (
    <Navigate to={routes.LOGIN} replace={true} />
  ) : (
    <>
      <aside className="aside pt-8x">
        <div className="logo text-center mb-8x">
          <img className="brand-logo" src={logo} alt="" />
          <img className="brand-logo-mini" src={logoMini} alt="" />
        </div>
        <nav className="aside__nav">
          <Scrollbars
            renderThumbVertical={() => (
              <div
                className={'scroll__thumb__vertical'}
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  borderRadius: '10px',
                }}
              />
            )}
          >
            <ul className="d-flex flex-direction-column">
              <li className="aside__nav__item">
                <NavLink to={routes.HOME} className="aside__nav__link">
                  <span className="aside__nav__link__span">
                    <Icon
                      className="mr-2x mt-neg"
                      icon="home"
                      size={12}
                      color="#DADADA"
                    />
                    <span className="nav__text">Home</span>
                  </span>
                </NavLink>
              </li>
              <li className="aside__nav__item">
                <NavLink
                  to={routes.INSURANCE_MAPPING}
                  className="aside__nav__link"
                >
                  <span className="aside__nav__link__span">
                    <Icon
                      className="mr-2x"
                      icon="receipt"
                      size={12}
                      color="#DADADA"
                    />
                    <span className="nav__text">Insurance Mapping</span>
                  </span>
                </NavLink>
              </li>
              <li className="aside__nav__item">
                <NavLink
                  to={routes.TRANSACTION_MAPPING}
                  className="aside__nav__link"
                >
                  <span className="aside__nav__link__span">
                    <Icon
                      className="mr-2x"
                      icon="monetization"
                      size={15}
                      color="#DADADA"
                    />
                    <span className="nav__text">Transaction Mapping</span>
                  </span>
                </NavLink>
              </li>
              <li className="aside__nav__item">
                <NavLink to={routes.ALGORITHMS} className="aside__nav__link">
                  <span className="aside__nav__link__span">
                    <Icon
                      className="mr-2x"
                      icon="algorithm"
                      size={12}
                      color="#DADADA"
                    />
                    <span className="nav__text">Algorithms</span>
                  </span>
                </NavLink>
              </li>
              <li className="aside__nav__item">
                <NavLink
                  to={routes.ACCOUNT_INFORMATION}
                  className="aside__nav__link"
                >
                  <span className="aside__nav__link__span">
                    <Icon
                      className="mr-2x"
                      icon="account"
                      size={12}
                      color="#DADADA"
                    />
                    <span className="nav__text">Account Information</span>
                  </span>
                </NavLink>
              </li>
              <hr className="my-6x mx-5x" />
              <li className="aside__nav__item">
                <NavLink to={routes.JOBS} className="aside__nav__link">
                  <span className="aside__nav__link__span">
                    <Icon
                      className="mr-2x"
                      icon="job"
                      size={12}
                      color="#DADADA"
                    />
                    <span className="nav__text">ETL Jobs</span>
                  </span>
                </NavLink>
              </li>
              <li className="aside__nav__item">
                <NavLink
                  to={routes.USER_MANAGEMENT}
                  className="aside__nav__link"
                >
                  <span className="aside__nav__link__span">
                    <Icon
                      className="mr-2x"
                      icon="person"
                      size={12}
                      color="#DADADA"
                      viewBox='0 0  16 16'
                    />
                    <span className="nav__text">User Management</span>
                  </span>
                </NavLink>
              </li>
            </ul>

            <div className="process__nav__wrapper">
              <hr className="my-6x mx-5x" />
              <h6 className="nav__title ">Process Analysis</h6>

              <ul className="process__nav">
                <li className="aside__nav__item">
                  <NavLink
                    to={routes.PROJECT_ANALYSIS}
                    className="aside__nav__link"
                  >
                    <span className="process__number">1</span>
                    Project Analysis
                  </NavLink>
                </li>
                <li className="aside__nav__item">
                  <NavLink
                    to={routes.DATA_CHOOSER}
                    className="aside__nav__link"
                  >
                    <span className="process__number">2</span>
                    Data Chooser
                  </NavLink>
                </li>
                <li className="aside__nav__item">
                  <NavLink
                    to={routes.VALIDATION_CHECKER}
                    className="aside__nav__link"
                  >
                    <span className="process__number">3</span>
                    Validation Checker
                  </NavLink>
                </li>
                <li className="aside__nav__item">
                  <NavLink to={routes.MAPPING} className="aside__nav__link">
                    <span className="process__number">4</span>
                    Mapping
                  </NavLink>
                </li>
                <li className="aside__nav__item">
                  <NavLink
                    to={routes.RULE_LIBRARY}
                    className="aside__nav__link"
                  >
                    <span className="process__number">5</span>
                    Rule Library
                  </NavLink>
                </li>
                <li className="aside__nav__item">
                  <NavLink to={routes.QA} className="aside__nav__link">
                    <span className="process__number">6</span>
                    QA
                  </NavLink>
                </li>
                <li className="aside__nav__item">
                  <NavLink to={routes.PUBLISHER} className="aside__nav__link">
                    <span className="process__number">7</span>
                    Publisher
                  </NavLink>
                </li>
                <li className="aside__nav__item">
                  <NavLink to={routes.STATEMENT} className="aside__nav__link">
                    <span className="process__number">8</span>
                    Statement
                  </NavLink>
                </li>
              </ul>
            </div>
          </Scrollbars>

          <button className="py-6x mb-0x logout" onClick={handleLogout}>
            <span className="aside__nav__link__span">
              <Icon className="mr-2x" icon="logout" size={12} color="#DADADA" />
              <span className="nav__text">Logout</span>
            </span>
          </button>
        </nav>

        <button className="aside__toggler" onClick={toggleHandler}>
          <Icon icon="chevronRight" width={7} height={15} color="#2B4154" />
        </button>
      </aside>
    </>
  );
};

export default Sidebar;
